import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Stepper from '../../components/CommonStepper';
import TableHeader from '../../components/TableHeader';
import UploadFile from './components/UploadFile';
import InactiveAccountsInFile from './components/InactiveAccountsInFile';
import NewAccountsInFile from './components/NewAccountsInFile';
import UpdatedAccountsInFile from './components/UpdatedAccountsInFile';
import FileUploadSyncSuccess from './components/FileUploadSyncSuccess';

const UploadFileWrapper = () => {
  return (
    <div className="main-wrapper">
      <Stepper />
      <TableHeader />
      <Switch>
        <Route exact path={ROUTES?.UPLOAD_FILE} component={UploadFile} />
        <Route
          exact
          path={ROUTES?.FILE_UPLOAD_EXISTING_ACCOUNTS}
          component={UpdatedAccountsInFile}
        />
        <Route
          exact
          path={ROUTES?.FILE_UPLOAD_NEW_ACCOUNTS}
          component={NewAccountsInFile}
        />
        <Route
          exact
          path={ROUTES?.FILE_UPLOAD_INACTIVE_ACCOUNTS}
          component={InactiveAccountsInFile}
        />
        <Route
          exact
          path={ROUTES?.FILE_UPLOAD_SYNC_COMPLETE}
          component={FileUploadSyncSuccess}
        />
      </Switch>
    </div>
  );
};

export default UploadFileWrapper;
