import React from 'react';
import { Pagination } from 'antd';
import { RECORDS_PER_PAGE } from '../common/constants';
import './CommonPagination.less';

const CommonPagination = ({ onChange, total, current }) => {
  return (
    <div className="pagination-main">
      <Pagination
        size="small"
        total={total}
        onChange={onChange}
        pageSize={RECORDS_PER_PAGE}
        showSizeChanger={false}
        current={current}
      />
    </div>
  );
};

export default CommonPagination;
