import React from 'react';
import { useLocation } from 'react-router-dom';
import AvailableCredits from './AvailableCredits';
import AzureSignIn from './AzureAuthentication';
import SettingsCard from './SettingsCard';
import { ROUTES } from '../../../common/constants';
import ConnectServer from './ConnectServer';

const MiddleLayout = () => {
  const location = useLocation();
  const currentPath = `/${location?.pathname?.split('/')?.[1]}`;

  return (
    <div className="middle-layout">
      <div className="middle-layout-margin">
        {currentPath === ROUTES?.AZURE_ACTIVE_DIRECTORY && <AzureSignIn />}
        {currentPath === ROUTES?.REMOTE_SERVER_ACCESS && <ConnectServer />}
        <SettingsCard />
        <AvailableCredits />
      </div>
    </div>
  );
};

export default MiddleLayout;
