import React, { createContext, useReducer } from 'react';
import api from './common/api';
import {
  AUTO_UPDATE,
  AZURE_ACCESS_TOKEN,
  AZURE_USER,
  CURRENTLY_UPLOADED_FILE,
  CURRENT_SECURITY_GROUP,
  CURRENT_STEP,
  FTP_AUTO_SYNC,
  FTP_INPUT,
  FTP_UPLOADED_FILE,
  ORDER_CONFIG_FOR_AUTO_SYNC,
  TEST_MODE,
  TOKEN,
  USER,
  WC_ORDER_IN_PROGRESS
} from './common/constants';

const getLoggedInUser = () => {
  // eslint-disable-next-line no-undef
  let loggedInUser = localStorage?.getItem(USER);
  loggedInUser = loggedInUser ? JSON?.parse(loggedInUser) : null;
  return loggedInUser;
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  // eslint-disable-next-line no-undef
  authToken: localStorage?.getItem(TOKEN),
  // eslint-disable-next-line no-undef
  azureAccessToken: localStorage?.getItem(AZURE_ACCESS_TOKEN),
  // eslint-disable-next-line no-undef
  azureUser: localStorage?.getItem(AZURE_USER),
  // eslint-disable-next-line no-undef
  currentStep: JSON?.parse(localStorage?.getItem(CURRENT_STEP)),
  // eslint-disable-next-line no-undef
  isTestModeOn: JSON?.parse(localStorage?.getItem(TEST_MODE)),
  // eslint-disable-next-line no-undef
  isAutoUpdateOn: JSON?.parse(localStorage?.getItem(AUTO_UPDATE)),
  // eslint-disable-next-line no-undef
  currentSecurityGroup: localStorage?.getItem(CURRENT_SECURITY_GROUP),
  // eslint-disable-next-line no-undef
  wcOrderInProgress: JSON?.parse(localStorage?.getItem(WC_ORDER_IN_PROGRESS)),
  FTPConnectionInProgress: false,
  availableCredits: {},
  availableCreditsLoading: false,
  // eslint-disable-next-line no-undef
  currentlyUploadedFile: localStorage?.getItem(CURRENTLY_UPLOADED_FILE),
  // eslint-disable-next-line no-undef
  ftpInput: JSON?.parse(localStorage?.getItem(FTP_INPUT)) || {},
  // eslint-disable-next-line no-undef
  FTPUploadedFile: localStorage?.getItem(FTP_UPLOADED_FILE),
  // eslint-disable-next-line no-undef
  FTPAutoSync: JSON?.parse(localStorage?.getItem(FTP_AUTO_SYNC)),
  autoSyncOrderConfig: JSON?.parse(
    // eslint-disable-next-line no-undef
    localStorage?.getItem(ORDER_CONFIG_FOR_AUTO_SYNC)
  ),
  refetchSyncLogs: false
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action?.data || {};
      // eslint-disable-next-line no-undef
      localStorage?.setItem(
        USER,
        user && Object?.keys(user).length ? JSON?.stringify(user) : null
      );
      return { ...state, currentUser: { ...user } };
    case 'SET_CURRENT_ROLE':
      return { ...state, currentRole: action?.data };
    case 'LOGOUT':
      delete api?.defaults?.headers?.common?.Authorization;
      // eslint-disable-next-line no-undef
      localStorage?.clear();

      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {}
      };
    case 'AZURE_LOGOUT':
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(AZURE_ACCESS_TOKEN);
      // eslint-disable-next-line no-undef
      localStorage?.removeItem(AZURE_USER);
      return {
        ...initialState,
        azureUser: {},
        azureAccessToken: null
      };
    case 'SET_FETCHING_USER_DETAILS':
      return { ...state, fetchingUserDetails: action?.data };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action?.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(TOKEN, action?.data);
      return { ...state, authToken: action?.data };
    case 'SET_AZURE_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(AZURE_ACCESS_TOKEN, action?.data);
      return { ...state, authToken: action?.data };
    case 'SET_AZURE_USER':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(AZURE_USER, action?.data);
      return { ...state, azureUser: action?.data };
    case 'SET_CURRENT_STEP':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(CURRENT_STEP, JSON?.stringify(action?.data));
      return { ...state, currentStep: action?.data };

    case 'TOGGLE_TEST_MODE':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(TEST_MODE, JSON?.stringify(action?.data));
      return { ...state, isTestModeOn: action?.data };

    case 'TOGGLE_AUTO_UPDATE':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(AUTO_UPDATE, JSON?.stringify(action?.data));
      return { ...state, isAutoUpdateOn: action?.data };

    case 'SET_CURRENT_SECURITY_GROUP':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(CURRENT_SECURITY_GROUP, action?.data);
      return { ...state, currentSecurityGroup: action?.data };

    case 'SET_WC_ORDER_STATUS':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(WC_ORDER_IN_PROGRESS, action?.data);
      return { ...state, wcOrderInProgress: action?.data };

    case 'SET_AVAILABLE_CREDITS':
      return { ...state, availableCredits: action?.data };

    case 'SET_AVAILABLE_CREDITS_LOADING':
      return { ...state, availableCreditsLoading: action?.data };

    case 'SET_CURRENTLY_UPLOADED_FILE':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(CURRENTLY_UPLOADED_FILE, action?.data);
      return { ...state, currentlyUploadedFile: action?.data };

    case 'SET_FTP_INPUT':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(FTP_INPUT, JSON?.stringify(action?.data));
      return { ...state, ftpInput: action?.data };

    case 'SET_FTP_UPLOADED_FILE':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(FTP_UPLOADED_FILE, action?.data);
      return { ...state, FTPUploadedFile: action?.data };

    case 'SET_FTP_AUTO_SYNC':
      // eslint-disable-next-line no-undef
      localStorage?.setItem(FTP_AUTO_SYNC, action?.data);
      return { ...state, FTPAutoSync: action?.data };

    case 'SET_REFETCH_SYNC_LOGS':
      return { ...state, refetchSyncLogs: action?.data };

    case 'SET_CONNECTION_IN_PROGRESS':
      return { ...state, FTPConnectionInProgress: action?.data };

    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {}
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => {
    // eslint-disable-next-line no-undef
    return localStorage?.getItem(TOKEN) || null;
  };

  const getCurrentUser = () => {
    // eslint-disable-next-line no-undef
    return localStorage?.getItem(USER)
      ? // eslint-disable-next-line no-undef
        JSON?.parse(localStorage?.getItem(USER))
      : {};
  };

  const getCurrentAzureUser = () => {
    // eslint-disable-next-line no-undef
    return localStorage?.getItem(AZURE_USER)
      ? // eslint-disable-next-line no-undef
        JSON?.parse(localStorage?.getItem(AZURE_USER))
      : {};
  };

  const getCurrentUserRole = () => {
    const loggedInUser = getLoggedInUser();
    return (
      (loggedInUser && loggedInUser?.roles && loggedInUser?.roles?.[0]) || ''
    );
  };

  const isAuthenticated = () => {
    return state?.authenticated;
  };

  const initializeAuth = (authToken, userData) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
      dispatch({ type: 'SET_CURRENT_USER', data: user });
      dispatch({
        type: 'SET_CURRENT_STEP',
        data: {
          activeDirectory: 0,
          remoteServerAccess: 0,
          uploadFile: 0
        }
      });

      dispatch({
        type: 'TOGGLE_TEST_MODE',
        data: {
          activeDirectory: false,
          uploadFile: false,
          remoteServerAccess: userData?.isUserConfig
            ? userData?.userDetailConfig?.isTestMode
            : false
        }
      });
      dispatch({
        type: 'TOGGLE_AUTO_UPDATE',
        data: {
          activeDirectory: false,
          remoteServerAccess: false,
          uploadFile: false
        }
      });
      dispatch({ type: 'SET_CURRENT_SECURITY_GROUP', data: null });
      dispatch({ type: 'SET_WC_ORDER_STATUS', data: false });
      dispatch({ type: 'SET_FTP_AUTO_SYNC', data: false });
    }
  };

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    initializeAuth,
    getCurrentUserRole,
    getCurrentUser,
    getCurrentAzureUser
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext?.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
