import React, { useContext, useState } from 'react';
import { Upload } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import CommonButton from '../../../components/CommonButton';
import UploadIcon from '../../../assets/svg/upload-icon.svg';
import DownloadIcon from '../../../assets/svg/download-icon.svg';
import DownloadTemplate from '../../../assets/files/template.xlsx';
import { ROUTES } from '../../../common/constants';
import './UploadFile.less';
import api from '../../../common/api';
import { AppContext } from '../../../AppContext';
import { currentActiveTabStep } from '../../../common/utils';

const UploadFile = () => {
  const {
    dispatch,
    state: { currentStep, isAutoUpdateOn, isTestModeOn, currentlyUploadedFile }
  } = useContext(AppContext);
  const history = useHistory();
  const currentActiveTab = currentActiveTabStep();
  const [uploadFileLoading, setUploadFileLoading] = useState(false);

  const handleFileChange = async (info) => {
    setUploadFileLoading(true);
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData?.append('file', info?.fileList?.[0]?.originFileObj);
    api
      ?.post('/file/file-upload', formData)
      ?.then((response) => {
        setUploadFileLoading(false);
        dispatch({
          type: 'SET_CURRENTLY_UPLOADED_FILE',
          data: response?.data?.data?.fileLocationUrl
        });

        if (isAutoUpdateOn?.[currentActiveTabStep()]) {
          if (!isTestModeOn?.[currentActiveTabStep()]) {
            api
              ?.post(
                `/file/user-update?isTestMode=${
                  isTestModeOn?.[currentActiveTabStep()]
                }&isAutoUpdate=${isAutoUpdateOn?.[currentActiveTabStep()]}`,
                {
                  fileLocationUrl: currentlyUploadedFile
                }
              )
              ?.catch((error) => {
                return error;
              });
          }
          dispatch({
            type: 'SET_CURRENT_STEP',
            data: { ...currentStep, [currentActiveTab]: 2 }
          });
          history?.push(ROUTES?.FILE_UPLOAD_NEW_ACCOUNTS);
        } else {
          dispatch({
            type: 'SET_CURRENT_STEP',
            data: { ...currentStep, [currentActiveTab]: 1 }
          });
          history?.push(ROUTES?.FILE_UPLOAD_EXISTING_ACCOUNTS);
        }
      })
      ?.catch((error) => {
        setUploadFileLoading(false);
        return error;
      });
  };
  const props = {
    accept: '.xlsx',
    name: 'file',
    beforeUpload: () => {
      return false;
    },
    onChange: handleFileChange
  };
  return (
    <div
      className={`upload-file-main ${
        currentStep?.[currentActiveTab] === 0 && 'upload-file-margin'
      }`}
    >
      {currentStep?.[currentActiveTab] === 0 && (
        <span className="upload-file-margin">Upload Your Team Directory</span>
      )}
      <div
        className={`actions-container ${
          currentStep?.[currentActiveTab] === 0 && 'upload-button-margin'
        }`}
      >
        <Upload {...props} maxCount={1} className="upload-button">
          <CommonButton type="primary" loading={uploadFileLoading}>
            <img src={UploadIcon} className="mr-10" alt="upload-file-logo" />
            Upload a file
          </CommonButton>
        </Upload>
        <Link to={DownloadTemplate} target="_blank" download>
          <CommonButton type="link">
            <img src={DownloadIcon} className="mr-10" alt="upload-file-logo" />
            Download template
          </CommonButton>
        </Link>
      </div>
    </div>
  );
};

export default UploadFile;
