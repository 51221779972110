import axios from 'axios';
import { message } from 'antd';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import * as Sentry from '@sentry/react';
import firebaseConfig from '../firebaseConfig';
import { SOMETHING_WENT_WRONG, TOAST_TYPES } from './constants';

initializeApp(firebaseConfig);
const authentication = getAuth();

const api = axios?.create({
  baseURL: process?.env?.REACT_APP_SERVER_URL
});

const toast = ({ message: content, type }) => {
  message?.destroy();
  switch (type) {
    case 'info':
      message?.info(content);
      break;
    case 'success':
      message?.success(content);
      break;
    case 'warning':
      message?.warning(content);
      break;
    case 'error':
      message?.error(content);
      break;
    default:
      break;
  }
};
const acquireFirebaseToken = () => {
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(authentication, (user) => {
      if (user) {
        unsubscribe();
        user?.getIdToken()?.then((token) => {
          resolve(token);
        });
      }
    });
  });
};

api.interceptors.request.use(
  async (config) => {
    try {
      const firebaseToken = await acquireFirebaseToken();
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${firebaseToken}`;
      return config;
    } catch (error) {
      return error;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

api?.interceptors?.response?.use(
  (response) => {
    if (
      response?.config?.method !== 'get' &&
      !response?.config?.url?.includes('credits/needed/credits') &&
      !response?.config?.url?.includes('file/user-info') &&
      !response?.config?.url?.includes('ftp/download_file') &&
      !response?.config?.url?.includes('ftp/disconnect')
    )
      toast({
        message: response?.data?.message || 'Operation successful',
        type: response?.status === 'ERROR' ? 'error' : 'success'
      });
    return response;
  },
  (error) => {
    if (error?.response) {
      Sentry.captureException(
        new Error(
          `[Response error]: Message: ${error?.response?.data?.message}`
        )
      );
      toast({
        message: error?.response?.data?.message || SOMETHING_WENT_WRONG,
        type: TOAST_TYPES.ERROR
      });
    }

    // following logic may be changed in the future
    if (error?.response?.data?.statusCode === 401) {
      getAuth()
        ?.signOut()
        ?.then(
          // eslint-disable-next-line no-undef
          localStorage?.clear(),
          // eslint-disable-next-line no-undef
          (window.location.href = ROUTES?.LOGIN)
        )
        ?.catch((firebaseError) => {
          return firebaseError;
        });
    }
  }
);

export default api;
