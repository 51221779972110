import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import React, { useRef } from 'react';
import './SearchComponent.less';

const SearchComponent = (dataIndex, setSearchText, onSearch, searchText) => {
  const searchInput = useRef(null);
  const handleSearch = (confirm) => {
    confirm();
    onSearch(searchText);
  };
  const handleReset = (confirm) => {
    setSearchText('');
    confirm();
    onSearch('');
  };

  return {
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="search-component-main">
        <div onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={searchText}
            onChange={(e) =>
              setSearchText(e.target.value ? e.target.value : '')
            }
            onPressEnter={() => handleSearch(confirm)}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(confirm)}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(confirm, clearFilters)}
              size="small"
            >
              Reset
            </Button>
          </Space>
        </div>
      </div>
    ),
    filterIcon: <SearchOutlined />
  };
};

export default SearchComponent;
