import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useMsal } from '@azure/msal-react';
import { ROUTES } from '../../common/constants';
import { AppContext } from '../../AppContext';
import SignOut from '../../assets/svg/sign-out.svg';

const Logout = () => {
  const history = useHistory();
  const {
    dispatch,
    state: { azureUser }
  } = useContext(AppContext);
  const { instance } = useMsal();

  const handleLogout = async () => {
    if (azureUser) {
      instance?.handleRedirectPromise()?.then(
        await instance?.logoutPopup()?.catch((error) => {
          return error;
        })
      );
      dispatch({ type: 'AZURE_LOGOUT' });
    }
    getAuth()
      ?.signOut()
      ?.then(
        () => dispatch({ type: 'LOGOUT' }),
        history?.replace(ROUTES?.LOGIN)
      )
      ?.catch((error) => {
        return error;
      });
  };

  return (
    <div
      onClick={handleLogout}
      className="mb-17 pointer d-flex justify-end align-center"
    >
      <img src={SignOut} alt="sign-out" className="mr-10" />
      <span className="font-16 font-red">Sign out</span>
    </div>
  );
};

export default Logout;
