import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import api from '../../../common/api';
import CommonButton from '../../../components/CommonButton';
import CommonTable from '../../../components/CommonTable';
import CommonPagination from '../../../components/CommonPagination';
import ConfirmDeactivateModal from '../../../components/ConfirmDeactivateModal';
import { AppContext } from '../../../AppContext';
import {
  RECORDS_PER_PAGE,
  ROUTES,
  TOAST_TYPES,
  UPLOAD_FILE_ERROR_MESSAGE,
  USERS_DELETED
} from '../../../common/constants';
import { currentActiveTabStep, toast } from '../../../common/utils';
import SearchComponent from '../../../components/SearchComponent';

const recentlyDeletedUsers = [];
const InactiveAccountsInFile = () => {
  const {
    state: {
      currentUser,
      isTestModeOn,
      currentlyUploadedFile,
      isAutoUpdateOn,
      currentStep
    },
    dispatch
  } = useContext(AppContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [inactiveAccounts, setInactiveAccounts] = useState(null);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState();
  const [deactivateLoading, setDeactivateLoading] = useState(false);
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const [searchText, setSearchText] = useState('');
  const [skip, setSkip] = useState(0);
  let deactivatePayload = [];
  const currentActiveTab = currentActiveTabStep();
  const fetchInactiveUsers = (searchValue = '') => {
    if (currentlyUploadedFile) {
      setLoading(true);
      api
        ?.post(
          `/file/user-info?skip=${skip}&limit=${RECORDS_PER_PAGE}&isTestMode=${
            isTestModeOn?.[currentActiveTabStep()]
          }&isAutoUpdate=${
            isAutoUpdateOn?.[currentActiveTabStep()]
          }&isInactiveAccount=true${
            searchValue !== '' ? `&searchValue=${searchValue}` : ''
          }`,
          {
            fileLocationUrl: currentlyUploadedFile
          }
        )
        ?.then((response) => {
          setInactiveAccounts(response?.data?.data?.userInfoData);
          setPaginationProp({
            ...paginationProp,
            total: response?.data?.data?.count
          });
          setLoading(false);
        })
        ?.catch((error) => {
          setLoading(false);
          return error;
        });
    } else {
      toast({
        message: UPLOAD_FILE_ERROR_MESSAGE,
        type: TOAST_TYPES.ERROR
      });
      dispatch({
        type: 'SET_CURRENT_STEP',
        data: { ...currentStep, [currentActiveTab]: 2 }
      });
      history?.push(ROUTES?.UPLOAD_FILE);
    }
  };
  const rowSelection = {
    renderCell(checked, record, index, node) {
      const status = <span className="font-red">Deleted</span>;
      if (
        recentlyDeletedUsers?.some(
          (recentlyDeleted) => recentlyDeleted?.userId === record?.userId
        )
      ) {
        return status;
      }
      return node;
    },
    checkStrictly: true,
    selectedRowKeys: selectedUsers,
    getCheckboxProps: (record) => ({
      disabled: record?.isDeleted
    }),
    onChange: (rows) => {
      setSelectedUsers(rows);
    }
  };

  useEffect(() => {
    fetchInactiveUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Full Name',
      key: 'Full Name',
      ...SearchComponent(
        'Full Name',
        setSearchText,
        fetchInactiveUsers,
        searchText
      ),
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'Title',
      render: (Title) => {
        return Title || '-';
      }
    },
    {
      title: 'Email',
      dataIndex: 'user',
      key: 'user',
      render: (userPrincipalName) => {
        return userPrincipalName || '-';
      }
    },
    {
      title: 'Select all',
      width: '11%',
      align: 'right'
    },
    Table?.SELECTION_COLUMN
  ];

  const handleDeactivateUsers = () => {
    setDeactivateLoading(true);
    if (!isTestModeOn?.[currentActiveTabStep()]) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < selectedUsers?.length; index++) {
        deactivatePayload?.push({
          userId: _.map(selectedUsers, 'userId')?.[index],
          userEmail: _.map(selectedUsers, 'user')?.[index]
        });
      }

      api
        ?.put('/active_directory/inactivate-user', {
          orgId: currentUser?.userDetails?.orgId,
          userIdWithEmail: deactivatePayload,
          inActive: true
        })
        ?.then((response) => {
          if (response) {
            recentlyDeletedUsers?.push(...selectedUsers);
          }
          deactivatePayload = [];
          setSelectedUsers([]);
          setDeactivateLoading(false);
          setConfirmModalVisible(false);
        })
        ?.catch((error) => {
          deactivatePayload = [];
          setSelectedUsers([]);
          setConfirmModalVisible(false);
          setDeactivateLoading(false);
          return error;
        });
    } else {
      setDeactivateLoading(false);
      setConfirmModalVisible(false);
      setSelectedUsers([]);
      toast({
        message: USERS_DELETED,
        type: TOAST_TYPES.SUCCESS
      });
    }
  };
  const handleTableChange = (currentPage) => {
    setSkip((currentPage - 1) * RECORDS_PER_PAGE);
    setPaginationProp({ ...paginationProp, current: currentPage });
  };

  return (
    <div className="inactive-accounts-main">
      <CommonTable
        rowKey={(e) => e}
        columns={columns}
        data={inactiveAccounts}
        loading={loading}
        rowSelection={{ type: 'checkbox', ...rowSelection }}
      />
      <div className="d-flex justify-between flex-wrap mt-8">
        <CommonPagination
          size="small"
          total={paginationProp?.total}
          onChange={handleTableChange}
          current={paginationProp?.current}
        />
        <div className="d-flex justify-end">
          <CommonButton
            type="primary"
            className="mr-5"
            onClick={() => history.push(ROUTES?.FILE_UPLOAD_SYNC_COMPLETE)}
            disabled={deactivateLoading}
          >
            Finish
          </CommonButton>
          <CommonButton
            disabled={!selectedUsers || selectedUsers?.length < 1}
            type="primary"
            className="mr-5"
            onClick={() => setConfirmModalVisible(true)}
          >
            Delete
          </CommonButton>
        </div>
        {confirmModalVisible && (
          <ConfirmDeactivateModal
            confirmModalVisible={confirmModalVisible}
            setConfirmModalVisible={setConfirmModalVisible}
            handleDeactivateUsers={handleDeactivateUsers}
            deactivateLoading={deactivateLoading}
            fetchInactiveUsers={fetchInactiveUsers}
          />
        )}
      </div>
    </div>
  );
};

export default InactiveAccountsInFile;
