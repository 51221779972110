import React, { useContext, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import CommonButton from '../../../components/CommonButton';
import SuccessIcon from '../../../assets/svg/order-confirmed.svg';
import { AppContext } from '../../../AppContext';
import {
  ACTIVE_TABS,
  CONNECTION_REQUEST,
  ROUTES
} from '../../../common/constants';
import './SyncSuccessful.less';
import { currentActiveTabStep } from '../../../common/utils';
import api from '../../../common/api';

const SyncSuccessful = () => {
  const {
    dispatch,
    state: { currentStep, currentUser }
  } = useContext(AppContext);
  const history = useHistory();
  const { instance } = useMsal();
  const currentActiveTab = currentActiveTabStep();
  const [loading, setLoading] = useState(false);
  const handleSignOut = async () => {
    instance
      ?.handleRedirectPromise()
      ?.then(
        await instance?.logoutPopup()?.catch((error) => {
          return error;
        })
      )
      ?.catch((error) => {
        return error;
      });
    dispatch({ type: 'AZURE_LOGOUT' });
    history.push(ROUTES.AZURE_ACTIVE_DIRECTORY);
  };
  const authentication = getAuth();
  const handleDisconnectServer = () => {
    // eslint-disable-next-line no-undef
    const disconnectPayload = JSON?.parse(
      // eslint-disable-next-line no-undef
      localStorage?.getItem(CONNECTION_REQUEST)
    );
    onAuthStateChanged(authentication, (user) => {
      if (user) {
        user?.getIdToken()?.then((token) => {
          api
            ?.post(
              `${process?.env?.REACT_APP_SERVER_URL}ftp/disconnect`,
              {
                ...disconnectPayload,
                isAutoSyncEnabled: false,
                orgId: currentUser?.userDetails?.orgId,
                id: currentUser?.userFtpDetail?.id
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            )
            .then(() => {
              setLoading(false);

              dispatch({ type: 'SET_FTP_AUTO_SYNC', data: false });
              dispatch({
                type: 'SET_FTP_INPUT',
                data: {}
              });
              dispatch({
                type: 'SET_CURRENT_STEP',
                data: {
                  ...currentStep,
                  [currentActiveTab]: 0
                }
              });
              history?.push(ROUTES?.REMOTE_SERVER_ACCESS);
            })
            .catch((error) => {
              return error;
            });
        });
      }
    });
  };
  const handleSyncAgain = () => {
    history?.push(
      currentActiveTab === ACTIVE_TABS?.activeDirectory
        ? ROUTES?.AZURE_ACTIVE_DIRECTORY
        : ROUTES?.FTP_EXISTING_ACCOUNTS
    );
  };

  const handleSyncComplete = () => {
    setLoading(true);
    handleDisconnectServer();
  };

  return (
    <div className="sync-successful-main">
      <span className="d-flex justify-center sync-heading text-align-center">
        Your Mobilo Team has Been Successfully Synced
      </span>
      <div className="d-flex justify-center success-icon">
        <img src={SuccessIcon} alt="success-icon" />
      </div>
      <div className="d-flex justify-center ">
        <CommonButton
          key="submit"
          className="mr-5"
          type={
            currentActiveTab === ACTIVE_TABS?.activeDirectory
              ? 'primary'
              : 'ghost'
          }
          onClick={handleSyncAgain}
        >
          Sync Again
        </CommonButton>

        {currentActiveTab === ACTIVE_TABS?.activeDirectory && (
          <CommonButton key="submit" type="danger" onClick={handleSignOut}>
            Sign Out
          </CommonButton>
        )}
        {currentActiveTab === ACTIVE_TABS?.remoteServerAccess && (
          <CommonButton
            key="submit"
            type="primary"
            onClick={handleSyncComplete}
            loading={loading}
          >
            Done
          </CommonButton>
        )}
      </div>
    </div>
  );
};

export default SyncSuccessful;
