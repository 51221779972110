import { MSAL_SCOPES } from './common/constants';

export const msalConfig = {
  auth: {
    clientId: process?.env?.REACT_APP_CLIENT_ID,
    authority: process?.env?.REACT_APP_AUTHORITY,
    redirectUri: process?.env?.REACT_APP_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const loginRequest = {
  scopes: [MSAL_SCOPES]
};
