import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Stepper from '../../components/CommonStepper';
import TableHeader from '../../components/TableHeader';
import SyncSuccessful from '../ActiveDirectory/components/SyncSuccessful';
import ExistingAccountsFTP from './components/ExistingAccountsFTP';
import InactiveAccountsFTP from './components/InactiveAccountsFTP';
import NewAccountsFTP from './components/NewAccountsFTP';
import ServerSyncTable from './components/ServerSyncTable';

const RemoteAccessWrapper = () => {
  const { pathname } = useLocation();
  return (
    <div
      className={`${
        pathname !== ROUTES?.REMOTE_SERVER_ACCESS && 'main-wrapper'
      }`}
    >
      {pathname !== ROUTES?.REMOTE_SERVER_ACCESS && (
        <>
          <Stepper />
          <TableHeader />
        </>
      )}
      <Switch>
        <Route
          exact
          path={ROUTES?.REMOTE_SERVER_ACCESS}
          component={ServerSyncTable}
        />
        <Route
          exact
          path={ROUTES?.FTP_EXISTING_ACCOUNTS}
          component={ExistingAccountsFTP}
        />

        <Route
          exact
          path={ROUTES?.FTP_NEW_ACCOUNTS}
          component={NewAccountsFTP}
        />

        <Route
          exact
          path={ROUTES?.FTP_INACTIVE_ACCOUNTS}
          component={InactiveAccountsFTP}
        />

        <Route
          exact
          path={ROUTES?.FTP_SYNC_SUCCESSFUL}
          component={SyncSuccessful}
        />
      </Switch>
    </div>
  );
};

export default RemoteAccessWrapper;
