import { Table } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import api from '../../../common/api';
import {
  NO_NEW_ACCOUNTS,
  RECORDS_PER_PAGE,
  ROUTES,
  TOAST_TYPES,
  UPLOAD_FILE_ERROR_MESSAGE
} from '../../../common/constants';
import { currentActiveTabStep, toast } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonPagination from '../../../components/CommonPagination';
import CommonTable from '../../../components/CommonTable';
import NewAccountConfiguration from '../../../components/NewAccountConfiguration';
import SearchComponent from '../../../components/SearchComponent';

const NewAccountsInFile = () => {
  const {
    dispatch,
    state: { isTestModeOn, currentlyUploadedFile, isAutoUpdateOn, currentStep }
  } = useContext(AppContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [newAccounts, setNewAccounts] = useState(null);
  const [configurationModalVisible, setConfigurationModalVisible] = useState(
    false
  );
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [getOldUsers, setGetOldUsers] = useState(false);
  const currentActiveTab = currentActiveTabStep();
  const source = axios?.CancelToken?.source();

  const handleSkip = () => {
    dispatch({
      type: 'SET_CURRENT_STEP',
      data: { ...currentStep, [currentActiveTab]: 2 }
    });
    history?.push(ROUTES?.FILE_UPLOAD_INACTIVE_ACCOUNTS);
  };

  const fetchNewUsers = (searchValue = '') => {
    if (currentlyUploadedFile) {
      setLoading(true);
      api
        ?.post(
          getOldUsers
            ? `/file/user-info?skip=${skip}&limit=${RECORDS_PER_PAGE}&isTestMode=${
                isTestModeOn?.[currentActiveTabStep()]
              }&isAutoUpdate=${
                isAutoUpdateOn?.[currentActiveTabStep()]
              }&isOldUsersWithNewUsers=true${
                searchValue !== '' ? `&searchValue=${searchValue}` : ''
              }`
            : `/file/user-info?skip=${skip}&limit=${RECORDS_PER_PAGE}&isTestMode=${
                isTestModeOn?.[currentActiveTabStep()]
              }&isAutoUpdate=${
                isAutoUpdateOn?.[currentActiveTabStep()]
              }&oldUserWithNewInfo=false${
                searchValue !== '' ? `&searchValue=${searchValue}` : ''
              }`,
          {
            fileLocationUrl: currentlyUploadedFile
          },
          {
            cancelToken: source?.token
          }
        )
        ?.then((response) => {
          setLoading(false);
          if (response) {
            if (response?.data?.data?.count > 0 || searchValue !== '') {
              setNewAccounts(response?.data?.data?.userInfoData);
              setPaginationProp({
                ...paginationProp,
                total: response?.data?.data?.count
              });
            } else {
              toast({
                message: NO_NEW_ACCOUNTS,
                type: TOAST_TYPES.ERROR
              });
              handleSkip();
            }
          }
        })
        ?.catch((error) => {
          setLoading(false);
          return error;
        });
    } else {
      toast({
        message: UPLOAD_FILE_ERROR_MESSAGE,
        type: TOAST_TYPES.ERROR
      });
      dispatch({
        type: 'SET_CURRENT_STEP',
        data: { ...currentStep, [currentActiveTab]: 2 }
      });
      history?.push(ROUTES?.UPLOAD_FILE);
    }
  };

  const rowSelection = {
    renderCell(checked, record, index, node) {
      const status = <span className="font-active">Added</span>;

      if (record?.isAdded) {
        return status;
      }
      return node;
    },
    checkStrictly: true,
    selectedRowKeys: selectedUsers,
    getCheckboxProps: (record) => ({
      disabled: record?.isAdded
    }),
    onChange: (rows) => {
      setSelectedUsers(rows);
    }
  };

  useEffect(() => {
    return () => {
      source?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchNewUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Full Name',
      key: 'Full Name',
      ...SearchComponent('Full Name', setSearchText, fetchNewUsers, searchText),
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'Title',
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Email',
      dataIndex: 'Email Address',
      key: 'Email Address',
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Select all',
      width: '11%',
      align: 'right'
    },
    Table?.SELECTION_COLUMN
  ];

  const handlePaginationChange = (currentPage) => {
    setSkip((currentPage - 1) * RECORDS_PER_PAGE);
    setPaginationProp({ ...paginationProp, current: currentPage });
  };

  return (
    <div className="new-accounts-main">
      <CommonTable
        rowKey={(e) => e}
        columns={columns}
        data={newAccounts}
        loading={loading}
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        paginationConfig={paginationProp}
      />
      <div className="d-flex justify-between align-center">
        <CommonPagination
          size="small"
          total={paginationProp?.total}
          onChange={handlePaginationChange}
          current={paginationProp?.current}
        />
        <div className="d-flex flex-wrap mt-8">
          <CommonButton
            type="primary"
            className="mr-5 mb-6"
            onClick={handleSkip}
          >
            Skip
          </CommonButton>
          <CommonButton
            disabled={!selectedUsers || selectedUsers?.length < 1 || loading}
            type="primary"
            className="mr-5 mb-6"
            onClick={() => setConfigurationModalVisible(true)}
          >
            Add
          </CommonButton>
        </div>
      </div>

      <NewAccountConfiguration
        configurationModalVisible={configurationModalVisible}
        setConfigurationModalVisible={setConfigurationModalVisible}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        fetchNewUsers={fetchNewUsers}
        setGetOldUsers={setGetOldUsers}
      />
    </div>
  );
};

export default NewAccountsInFile;
