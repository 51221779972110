import React from 'react';
import { Modal } from 'antd';

const CommonModal = ({
  children,
  previewVisible,
  previewImage,
  previewTitle,
  setPreviewVisible,
  altName,
  onCancel = () => setPreviewVisible(false),
  ...props
}) => {
  return (
    <Modal
      wrapClassName="preview-modal"
      destroyOnClose
      centered
      visible={previewVisible}
      title={previewTitle}
      footer={null}
      onCancel={onCancel}
      mask={false}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default CommonModal;
