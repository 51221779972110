import React from 'react';

import CommonButton from './CommonButton';
import CommonModal from './CommonModal';

import './ConfirmDeactivateModal.less';

const ConfirmDeactivateModal = ({
  confirmModalVisible,
  setConfirmModalVisible,
  handleDeactivateUsers,
  deactivateLoading
}) => {
  return (
    <CommonModal
      previewVisible={confirmModalVisible}
      setPreviewVisible={setConfirmModalVisible}
    >
      <div>
        <div className="d-flex justify-center deactivate-confirmation-main">
          <span className="font-10 text-align-center">
            Are you sure you want to delete these accounts? This action is
            irreversible.
          </span>
        </div>
        <div className="modal-footer">
          <CommonButton
            key="submit"
            className="mr-5"
            type="ghost"
            onClick={() => setConfirmModalVisible(false)}
          >
            No
          </CommonButton>

          <CommonButton
            key="submit"
            type="primary"
            onClick={handleDeactivateUsers}
            loading={deactivateLoading}
          >
            Yes
          </CommonButton>
        </div>
      </div>
    </CommonModal>
  );
};

export default ConfirmDeactivateModal;
