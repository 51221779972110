import React, { useContext, useEffect } from 'react';
import { Steps } from 'antd';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import './CommonStepper.less';
import {
  Step1Progress,
  Step2Progress,
  Step3Progress,
  Step4Progress,
  Step1Waiting,
  Step2Waiting,
  Step3Waiting,
  Step4Waiting,
  Step1Complete,
  Step2Complete,
  Step3Complete,
  Step4Complete
} from '../assets/svg';
import { ACTIVE_TABS, ROUTES } from '../common/constants';
import { currentActiveTabStep } from '../common/utils';

const { Step } = Steps;

const Stepper = () => {
  const {
    state: { currentStep },
    dispatch
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const currentActiveTab = currentActiveTabStep();

  const currentPath = `${pathname?.split('/')?.[1]}`;
  useEffect(() => {
    if (
      pathname?.includes(ROUTES?.SECURITY_GROUPS) ||
      pathname === ROUTES?.UPLOAD_FILE ||
      pathname === ROUTES?.REMOTE_SERVER_ACCESS
    ) {
      dispatch({
        type: 'SET_CURRENT_STEP',
        data: { ...currentStep, [currentActiveTab]: 0 }
      });
    } else if (
      pathname?.includes(ROUTES?.UPDATE_EXISTING_ACCOUNTS) ||
      pathname?.includes(ROUTES?.FILE_UPLOAD_EXISTING_ACCOUNTS) ||
      pathname?.includes(ROUTES?.FTP_EXISTING_ACCOUNTS)
    ) {
      dispatch({
        type: 'SET_CURRENT_STEP',
        data: { ...currentStep, [currentActiveTab]: 1 }
      });
    } else if (
      pathname?.includes(ROUTES?.NEW_ACCOUNTS) ||
      pathname?.includes(ROUTES?.FILE_UPLOAD_NEW_ACCOUNTS) ||
      pathname?.includes(ROUTES?.FTP_NEW_ACCOUNTS)
    ) {
      dispatch({
        type: 'SET_CURRENT_STEP',
        data: { ...currentStep, [currentActiveTab]: 2 }
      });
    } else if (
      pathname?.includes(ROUTES?.INACTIVE_ACCOUNTS) ||
      pathname?.includes(ROUTES?.DEACTIVATE_ACCOUNTS) ||
      pathname?.includes(ROUTES?.ACTIVE_DIRECTORY_SYNC_COMPLETE) ||
      pathname?.includes(ROUTES?.FILE_UPLOAD_INACTIVE_ACCOUNTS) ||
      pathname?.includes(ROUTES?.FILE_UPLOAD_SYNC_COMPLETE) ||
      pathname?.includes(ROUTES?.FTP_INACTIVE_ACCOUNTS) ||
      pathname?.includes(ROUTES?.FTP_SYNC_SUCCESSFUL)
    ) {
      dispatch({
        type: 'SET_CURRENT_STEP',
        data: { ...currentStep, [currentActiveTab]: 3 }
      });
    } else {
      dispatch({
        type: 'SET_CURRENT_STEP',
        data: { ...currentStep, [currentActiveTab]: 0 }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getStepOneText = () => {
    switch (currentPath) {
      case 'active-directory':
        return currentStep?.[currentActiveTab] <= 0
          ? 'Syncing directory'
          : 'Directory synced';
      case 'remote-server-access':
        return 'Team directoy uploaded';
      case 'upload-file':
        return currentStep?.[currentActiveTab] <= 0
          ? 'Upload team directoy'
          : 'Team directoy uploaded';
      default:
        return 'Syncing directory';
    }
  };

  const setCurretIcon = (
    index = null,
    waitingIcon = '',
    progressIcon = '',
    completedIcon = ''
  ) => {
    if (currentStep?.[currentActiveTab] === index) {
      return progressIcon;
    }
    if (currentStep?.[currentActiveTab] > index) {
      return completedIcon;
    }
    return waitingIcon;
  };

  const steps = [
    {
      key: 'SYNC-DIRECTORY',
      title: getStepOneText(),
      icon: setCurretIcon(
        0,
        <Step1Waiting className="step-icons" />,
        <Step1Progress className="progress-icons" />,
        <Step1Complete className="step-icons" />
      )
    },
    {
      key: 'UPDATE-ACCOUNTS',
      title:
        currentStep?.[currentActiveTab] <= 1
          ? 'Updating existent accounts'
          : 'Existent accounts updated',
      icon: setCurretIcon(
        1,
        <Step2Waiting className="step-icons" />,
        <Step2Progress className="progress-icons" />,
        <Step2Complete className="step-icons" />
      )
    },
    {
      key: 'ADD_NEW_ACCOUNTS',
      title:
        currentStep?.[currentActiveTab] <= 2
          ? 'Adding new accounts'
          : 'New accounts added',
      icon: setCurretIcon(
        2,
        <Step3Waiting className="step-icons" />,
        <Step3Progress className="progress-icons" />,
        <Step3Complete className="step-icons" />
      )
    },
    {
      key: 'DEACTIVATE-ACCOUNTS',
      title:
        pathname?.includes(ROUTES?.ACTIVE_DIRECTORY_SYNC_COMPLETE) ||
        pathname?.includes(ROUTES?.FILE_UPLOAD_SYNC_COMPLETE)
          ? 'Inactive accounts deleted'
          : 'Deleting inactive accounts',
      icon: setCurretIcon(
        3,
        <Step4Waiting className="step-icons" />,
        <Step4Progress className="progress-icons" />,
        <Step4Complete className="step-icons" />
      )
    }
  ];
  return (
    <div className="stepper-main">
      <Steps
        size="small"
        labelPlacement="vertical"
        current={currentStep?.[currentActiveTab]}
      >
        {steps?.map((item) => {
          return (
            <>
              <Step
                key={item?.key}
                title={item?.title}
                icon={item?.icon}
                className={`${
                  item?.key === 'SYNC-DIRECTORY' &&
                  currentActiveTab === ACTIVE_TABS?.activeDirectory &&
                  'title-padding'
                }`}
              />
            </>
          );
        })}
      </Steps>
    </div>
  );
};

export default Stepper;
