import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import ActiveDirectoryWrapper from '../modules/ActiveDirectory';
import RemoteAccessWrapper from '../modules/RemoteServerAccess';
import UploadFileWrapper from '../modules/UploadFile';
import { ROUTES } from '../common/constants';

const ContentRoutes = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={ROUTES?.MAIN}
          render={() => <Redirect to={ROUTES?.AZURE_ACTIVE_DIRECTORY} />}
        />
        <Route
          path={ROUTES?.AZURE_ACTIVE_DIRECTORY}
          component={ActiveDirectoryWrapper}
        />
        <Route
          path={ROUTES?.REMOTE_SERVER_ACCESS}
          component={RemoteAccessWrapper}
        />
        <Route path={ROUTES?.UPLOAD_FILE} component={UploadFileWrapper} />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
