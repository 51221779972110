import { message } from 'antd';
import moment from 'moment';
import api from './api';
import { ACTIVE_TABS, defaultDateFormat, REGEX, ROUTES } from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) => {
  // eslint-disable-next-line no-undef
  return document?.getElementById(portalId);
};

export const isPortalIdExists = (portalId) => {
  return !!injectUsingPortal(portalId);
};

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) => {
  // eslint-disable-next-line no-undef
  return document?.getElementById(portalId);
};

export const isDocumentIdExist = (portalId) => {
  return !!getElementFromDocumentId(portalId);
};
// Check for document Id's exists end

export const formatDate = (
  datetime,
  format = `${defaultDateFormat} hh:mm A`
) => {
  if (datetime && moment && format) {
    return moment(datetime)?.format(format);
  }

  return datetime;
};

export const currentActiveTabStep = () => {
  // eslint-disable-next-line no-restricted-globals, no-undef
  if (location?.pathname?.includes(ROUTES?.AZURE_ACTIVE_DIRECTORY)) {
    return ACTIVE_TABS?.activeDirectory;
  }
  // eslint-disable-next-line no-restricted-globals, no-undef
  if (location?.pathname?.includes(ROUTES?.REMOTE_SERVER_ACCESS)) {
    return ACTIVE_TABS?.remoteServerAccess;
  }
  // eslint-disable-next-line no-restricted-globals, no-undef
  if (location?.pathname?.includes(ROUTES?.UPLOAD_FILE)) {
    return ACTIVE_TABS?.uploadFile;
  }
};

export const findRouteByStep = (currentIndexRoute, currentStep) => {
  switch (currentIndexRoute) {
    case ACTIVE_TABS?.activeDirectory:
      switch (currentStep) {
        case 0:
          return ROUTES?.AZURE_ACTIVE_DIRECTORY;
        case 1:
          return ROUTES?.UPDATE_EXISTING_ACCOUNTS;
        case 2:
          return ROUTES?.NEW_ACCOUNTS;
        case 3:
          return ROUTES?.DEACTIVATE_ACCOUNTS;
        default:
          return ROUTES?.AZURE_ACTIVE_DIRECTORY;
      }

    case ACTIVE_TABS?.uploadFile:
      switch (currentStep) {
        case 0:
          return ROUTES?.UPLOAD_FILE;
        case 1:
          return ROUTES?.FILE_UPLOAD_EXISTING_ACCOUNTS;
        case 2:
          return ROUTES?.FILE_UPLOAD_NEW_ACCOUNTS;
        case 3:
          return ROUTES?.FILE_UPLOAD_INACTIVE_ACCOUNTS;
        default:
          return ROUTES?.UPLOAD_FILE;
      }
    case ACTIVE_TABS?.remoteServerAccess:
      switch (currentStep) {
        case 0:
          return ROUTES?.REMOTE_SERVER_ACCESS;
        case 1:
          return ROUTES?.FTP_EXISTING_ACCOUNTS;
        case 2:
          return ROUTES?.FTP_NEW_ACCOUNTS;
        case 3:
          return ROUTES?.FTP_INACTIVE_ACCOUNTS;
        default:
          return ROUTES?.REMOTE_SERVER_ACCESS;
      }
    default:
      return 0;
  }
};

export const toast = ({ message: content, type }) => {
  switch (type) {
    case 'info':
      message?.info(content);
      break;
    case 'success':
      message?.success(content);
      break;
    case 'warning':
      message?.warning(content);
      break;
    case 'error':
      message?.error(content);
      break;
    default:
      break;
  }
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required'
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!'
  },

  ipAddress: () => ({
    required: true,
    validator(rule, value) {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Required');
      }
      if (!REGEX?.IP_ADDRESS?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter valid host');
      }
      return Promise?.resolve();
    }
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    }
  })
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes()
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD'
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

// Note : Function to upload on s3 bucket
export async function fileUpload(signedUrl, image, onUploadProgress) {
  try {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr?.open('PUT', signedUrl);
      xhr?.setRequestHeader('Content-Type', image?.type);
      xhr?.setRequestHeader('x-amz-acl', 'public-read');
      xhr?.addEventListener('readystatechange', function () {
        if (this?.readyState === 4) {
          resolve(xhr?.response);
        }
      });
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math?.ceil((e?.loaded / e?.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr?.send(image);
    });
  } catch (error) {
    message?.error(error?.message);
  }
}

// temporarily commenting thew following code for future reference

export const getSignedUrl = async () => {
  // const fileName = fileObj?.name;
  // const extension = fileName.slice(fileName.lastIndexOf('.') + 1);
  // const key = `${fileName}`;
  // const response = await client.mutate({
  //   mutation: GET_SIGNED_URL,
  //   variables: {
  //     action: 'write',
  //     data: {
  //       extension: `.${extension}`,
  //       contentType: fileObj?.type,
  //       key
  //     }
  //   }
  // });
  // if (response) {
  //   return response?.data;
  // }
  return null;
};

export const fetchImage = async () => {
  // const fileName = fileObj?.name;
  // const extension = fileName.slice(fileName.lastIndexOf('.') + 1);
  // const key = `${fileName}`;
  // const response = await client.mutate({
  //   mutation: GET_SIGNED_URL,
  //   variables: {
  //     action: 'read',
  //     data: {
  //       extension: `.${extension}`,
  //       contentType: fileObj?.type,
  //       key
  //     }
  //   }
  // });
  // if (response) {
  //   return response?.data;
  // }
  return null;
};

export const uploadImage = async (signedRequest, fileObj) => {
  await api(signedRequest, {
    method: 'PUT',
    data: fileObj?.originFileObj || fileObj,
    headers: {
      'Content-Type': fileObj?.type
    }
  });
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader?.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getTableHeading = (currentStep) => {
  const currentActiveTab = currentActiveTabStep();
  switch (currentStep) {
    case 0:
      return 'Select the Security Group';
    case 1:
      return 'Existent Accounts with New Information';
    case 2:
      return 'New Accounts Found ';
    case 3:
      switch (currentActiveTab) {
        case ACTIVE_TABS?.activeDirectory:
          return 'Accounts not Found in the Directory';
        case ACTIVE_TABS?.uploadFile:
          return 'Inactive Accounts';
        default:
          return 'Inactive Accounts';
      }
    default:
      break;
  }
};
