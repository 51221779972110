import { Collapse, Spin } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import api from '../../../common/api';
import {
  FAILED,
  PENDING,
  RECORDS_PER_PAGE,
  SUCCESS,
  SYNC_DATE_FORMAT
} from '../../../common/constants';
import CommonPagination from '../../../components/CommonPagination';
import RemoteServerEmptyScreen from './RemoteServerEmptyScreen';
import './ServerSyncTable.less';
import SuccessfulSyncLog from './SuccessfulSyncLog';

const ServerSyncTable = () => {
  const { Panel } = Collapse;

  const {
    state: { currentUser, refetchSyncLogs },
    dispatch
  } = useContext(AppContext);
  const [syncLogsLoading, setSyncLogsLoading] = useState(false);
  const [syncLogs, setSyncLogs] = useState([]);
  const [skip, setSkip] = useState(0);
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });

  const fetchSyncLogList = () => {
    setSyncLogsLoading(true);
    api
      ?.get(
        `ftp/get-org-cron-details?orgId=${currentUser?.userDetails?.orgId}&skip=${skip}&limit=${RECORDS_PER_PAGE}`
      )
      .then((response) => {
        setPaginationProp({
          ...paginationProp,
          total: response?.data?.data?.count
        });
        setSyncLogs(response?.data?.data?.orgData);
        setSyncLogsLoading(false);
        dispatch({ type: 'SET_REFETCH_SYNC_LOGS', data: false });
      })
      .catch((error) => {
        dispatch({ type: 'SET_REFETCH_SYNC_LOGS', data: false });
        setSyncLogsLoading(false);
        return error;
      });
  };

  useEffect(() => {
    fetchSyncLogList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, refetchSyncLogs]);

  const handlePagination = (currentPage) => {
    setSkip((currentPage - 1) * RECORDS_PER_PAGE);
    setPaginationProp({ ...paginationProp, current: currentPage });
  };

  const getSyncLogHeading = (status) => {
    switch (status) {
      case SUCCESS:
        return 'Successful Server Sync';
      case FAILED:
        return 'Failed Server Sync';
      case PENDING:
        return 'Pending Server Sync';
      default:
        return 'Successful Server Sync';
    }
  };

  return (
    <div className="server-sync-table-main">
      <>
        <Spin
          spinning={syncLogsLoading}
          size="large"
          className="parent-spinner"
          tip="Please wait while sync-logs are loading"
        >
          {syncLogs?.length > 0 ? (
            <>
              <Collapse
                // eslint-disable-next-line no-underscore-dangle
                defaultActiveKey={syncLogs?.[0]?._id}
              >
                {syncLogs?.map((syncLog) => {
                  return (
                    <Panel
                      header={getSyncLogHeading(syncLog?.status)}
                      // eslint-disable-next-line no-underscore-dangle
                      key={syncLog?._id}
                      extra={`${moment(syncLog?.createdAt_utc)
                        ?.utc()
                        ?.format(SYNC_DATE_FORMAT)} UTC`}
                    >
                      <div className="main-wrapper">
                        <SuccessfulSyncLog syncLog={syncLog} />
                      </div>
                    </Panel>
                  );
                })}
              </Collapse>
              <div className="d-flex justify-between flex-wrap mt-8">
                <CommonPagination
                  size="small"
                  total={paginationProp?.total}
                  onChange={handlePagination}
                  current={paginationProp?.current}
                />
              </div>
            </>
          ) : (
            <div>
              <RemoteServerEmptyScreen emptySynclogs />
            </div>
          )}
        </Spin>
      </>
    </div>
  );
};

export default ServerSyncTable;
