import React, { useContext, useEffect, useState } from 'react';
import { Card, Checkbox, Form, Input, Spin } from 'antd';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { formValidatorRules, toast } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import SelectFileModal from '../../../modules/RemoteServerAccess/components/SelectFileModal';
import './ConnectServer.less';
import api from '../../../common/api';
import { AppContext } from '../../../AppContext';
import {
  CONNECTION_REQUEST,
  FTP_DISCONNECTED,
  FTP_INPUT,
  TOAST_TYPES
} from '../../../common/constants';

let fileList = [];
let onetimeUploadInitialValues = JSON?.parse(
  // eslint-disable-next-line no-undef
  localStorage?.getItem(CONNECTION_REQUEST)
);
const ConnectServer = () => {
  const authentication = getAuth();

  const {
    dispatch,
    state: { ftpInput, currentUser }
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const FTPAutoSync = currentUser?.isAutoSyncEnabled;
  const [selectFileVisible, setSelectFileVisible] = useState(false);
  const { required } = formValidatorRules;
  const [connectServerLoading, setConnectServerLoading] = useState(false);
  const [disconnectLoading, setDisconnectLoading] = useState(false);
  const [autoSyncLoading, setAutoSyncLoading] = useState(false);
  const [deleteFileAfterUpload, setDeleteFileAfterUpload] = useState(
    // eslint-disable-next-line no-nested-ternary
    FTPAutoSync
      ? currentUser?.userFtpDetail?.deleteFileAfterUpload
      : Object?.keys(ftpInput)?.length > 0
      ? onetimeUploadInitialValues?.deleteFileAfterUpload
      : false
  );
  const [connectionRequest, setConnectionRequest] = useState({});
  const [profileLoading, setProfileLoading] = useState(false);
  const getUserConfig = () => {
    setProfileLoading(true);
    api
      ?.get('profile/get-profile')
      ?.then((response) => {
        if (response?.data?.data) {
          dispatch({
            type: 'SET_CURRENT_USER',
            data: response?.data?.data
          });
          setProfileLoading(false);
        }
      })
      ?.catch((error) => {
        setProfileLoading(false);
        return error;
      });
  };

  const handleAutoSync = async () => {
    if (!currentUser?.isUserConfig) {
      toast({
        message:
          'Please confirm the standard user configuration below, in order to proceed with the Auto Sync',
        type: 'error'
      });
    } else {
      const {
        host,
        username,
        password,
        filePath,
        port
      } = form.getFieldsValue();
      // eslint-disable-next-line no-undef
      localStorage?.setItem(
        CONNECTION_REQUEST,
        JSON?.stringify(form.getFieldsValue()),
        deleteFileAfterUpload
      );

      onetimeUploadInitialValues = JSON.parse(
        // eslint-disable-next-line no-undef
        localStorage?.getItem(CONNECTION_REQUEST)
      );

      setConnectionRequest({ host, username, password, filePath, port });
      setAutoSyncLoading(true);
      api({
        method: `${currentUser?.isUserDetail ? 'put' : 'post'}`,
        url: 'automated_sync/ftp',
        data: {
          host,
          port,
          username,
          password,
          filePath,
          deleteFileAfterUpload,
          isAutoSyncEnabled: true,
          ...(currentUser?.isUserDetail && {
            id: currentUser?.userFtpDetail?.id
          })
        }
      })
        .then(() => {
          getUserConfig();
          dispatch({ type: 'SET_REFETCH_SYNC_LOGS', data: true });
          setAutoSyncLoading(false);
        })
        .catch((error) => {
          setAutoSyncLoading(false);
          return error;
        });
    }
  };

  const handleDisconnectServer = ({ isRefresh, disconnectOneTimeUpload }) => {
    // eslint-disable-next-line no-undef
    localStorage?.setItem(
      CONNECTION_REQUEST,
      JSON?.stringify(form.getFieldsValue())
    );
    if (!isRefresh) setDisconnectLoading(true);

    // eslint-disable-next-line no-undef
    const disconnectPayload = JSON?.parse(
      // eslint-disable-next-line no-undef
      localStorage?.getItem(CONNECTION_REQUEST)
    );
    onAuthStateChanged(authentication, (user) => {
      if (user) {
        user?.getIdToken()?.then((token) => {
          api
            ?.post(
              `${process?.env?.REACT_APP_SERVER_URL}ftp/disconnect`,
              {
                ...disconnectPayload,
                isAutoSyncEnabled: false,
                orgId: currentUser?.userDetails?.orgId,
                id: currentUser?.userFtpDetail?.id
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            )
            .then(() => {
              setDisconnectLoading(false);
              if (isRefresh) {
                handleAutoSync();
              } else if (!isRefresh && !disconnectOneTimeUpload) {
                toast({
                  message: FTP_DISCONNECTED,
                  type: TOAST_TYPES.SUCCESS
                });
                getUserConfig();
                dispatch({ type: 'SET_FTP_AUTO_SYNC', data: false });
              }
            })
            .catch((error) => {
              setAutoSyncLoading(false);
              setDisconnectLoading(false);
              return error;
            });
        });
      }
    });
  };

  const handleOneTimeUpload = async () => {
    dispatch({
      type: 'SET_CONNECTION_IN_PROGRESS',
      data: true
    });
    const { host, username, password, filePath, port } = form.getFieldsValue();
    // eslint-disable-next-line no-undef
    localStorage?.setItem(
      CONNECTION_REQUEST,
      JSON?.stringify({ ...form.getFieldsValue(), deleteFileAfterUpload })
    );
    setConnectionRequest({ host, username, password, filePath, port });

    fileList = [];
    setConnectServerLoading(true);
    api
      ?.post(`ftp/connect`, { host, username, password, filePath, port })
      .then((response) => {
        dispatch({
          type: 'SET_CONNECTION_IN_PROGRESS',
          data: false
        });
        if (response?.data?.files) {
          dispatch({
            type: 'SET_FTP_INPUT',
            data: { filePath, deleteFileAfterUpload }
          });
          for (
            let index = 0;
            index < response?.data?.files?.length;
            // eslint-disable-next-line no-plusplus
            index++
          ) {
            fileList.push({ file: response?.data?.files?.[index] });
          }
          setSelectFileVisible(true);
        }
        setConnectServerLoading(false);
      })
      .catch((error) => {
        dispatch({
          type: 'SET_CONNECTION_IN_PROGRESS',
          data: false
        });

        setConnectServerLoading(false);
        return error;
      });
  };

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (!ftpInput?.hasOwnProperty('fileLocationUrl')) {
      // eslint-disable-next-line no-undef
      localStorage?.setItem(FTP_INPUT, JSON?.stringify({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ftpInput]);

  return (
    <Spin spinning={profileLoading}>
      <div className="connect-server-main mb-17">
        <Card
          className="connect-server-wrapper heading-blue"
          title="Connect your Server"
          extra={
            FTPAutoSync && (
              <div className="auto-sync-active">Auto-Sync enabled</div>
            )
          }
        >
          <Form
            layout="vertical"
            form={form}
            className="mt-4"
            requiredMark={false}
            initialValues={
              Object?.keys(ftpInput)?.length > 0
                ? {
                    ...onetimeUploadInitialValues
                  }
                : {
                    username: currentUser?.userFtpDetail?.username,
                    filePath: currentUser?.userFtpDetail?.filePath,
                    port: currentUser?.userFtpDetail?.port,
                    host: currentUser?.userFtpDetail?.host
                  }
            }
          >
            <Form.Item label="Host name" name="host" rules={[required]}>
              <Input placeholder="Enter your host name" />
            </Form.Item>

            <Form.Item label="Username" name="username" rules={[required]}>
              <Input placeholder="Enter your username" />
            </Form.Item>

            <Form.Item label="Password" name="password" rules={[required]}>
              <Input.Password
                placeholder="Password"
                className="connect-server-password"
              />
            </Form.Item>

            <Form.Item label="Port" name="port" rules={[required]}>
              <Input type="number" placeholder="Enter your port" />
            </Form.Item>

            <Form.Item label="File Path" name="filePath" rules={[required]}>
              <Input placeholder="/" />
            </Form.Item>

            <Form.Item rules={[required]}>
              <Checkbox
                onChange={() =>
                  setDeleteFileAfterUpload(!deleteFileAfterUpload)
                }
                value={deleteFileAfterUpload}
                defaultChecked={deleteFileAfterUpload}
              >
                Delete the uploaded file from my server
              </Checkbox>
            </Form.Item>

            <div className="d-flex justify-between">
              {!FTPAutoSync ? (
                <>
                  <Form.Item>
                    <CommonButton
                      type="ghost"
                      disabled={
                        Object?.keys(ftpInput)?.length > 0 || autoSyncLoading
                      }
                      loading={connectServerLoading}
                      onClick={() => {
                        form.validateFields().then(() => {
                          handleOneTimeUpload();
                        });
                      }}
                    >
                      One-time upload
                    </CommonButton>
                  </Form.Item>

                  <Form.Item>
                    <CommonButton
                      type="primary"
                      loading={autoSyncLoading}
                      disabled={
                        Object?.keys(ftpInput)?.length > 0 ||
                        connectServerLoading
                      }
                      onClick={() => {
                        form.validateFields().then(() => {
                          handleAutoSync();
                        });
                      }}
                    >
                      Enable Auto-Sync
                    </CommonButton>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item>
                    <CommonButton
                      type="ghost"
                      className="button-outline-red"
                      onClick={() =>
                        handleDisconnectServer({ isRefresh: false })
                      }
                      loading={disconnectLoading}
                    >
                      Disconnect
                    </CommonButton>
                  </Form.Item>
                  <Form.Item>
                    <CommonButton
                      type="primary"
                      htmlType="submit"
                      loading={autoSyncLoading}
                      onClick={() => {
                        form.validateFields().then(() => {
                          setAutoSyncLoading(true);
                          handleDisconnectServer({ isRefresh: true });
                        });
                      }}
                    >
                      Refresh
                    </CommonButton>
                  </Form.Item>
                </>
              )}
            </div>
          </Form>
        </Card>

        {selectFileVisible && (
          <SelectFileModal
            selectFileVisible={selectFileVisible}
            setSelectFileVisible={setSelectFileVisible}
            fileList={fileList}
            connectionRequest={connectionRequest}
            deleteFileAfterUpload={deleteFileAfterUpload}
            handleDisconnectServer={handleDisconnectServer}
          />
        )}
      </div>
    </Spin>
  );
};

export default ConnectServer;
