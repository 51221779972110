import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import './DeactivateAccounts.less';

const DeactivateAccounts = () => {
  const history = useHistory();
  const handleSkip = () => {
    history?.push(ROUTES?.ACTIVE_DIRECTORY_SYNC_COMPLETE);
  };

  const handleSync = () => {
    history?.push(ROUTES?.INACTIVE_ACCOUNTS);
  };
  return (
    <>
      <div className="deactivate-accounts-main">
        <div className="d-flex justify-center">
          Syncing all accounts in the directory is required to identify inactive
          accounts
        </div>
      </div>
      <div className="d-flex justify-center">
        <CommonButton
          key="submit"
          className="mr-5 button-skip"
          type="ghost"
          onClick={handleSkip}
        >
          Skip
        </CommonButton>

        <CommonButton key="submit" type="primary" onClick={handleSync}>
          Sync all accounts
        </CommonButton>
      </div>
    </>
  );
};

export default DeactivateAccounts;
