import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import api from '../../../common/api';
import {
  RECORDS_PER_PAGE,
  ROUTES,
  TOAST_TYPES,
  UPLOAD_FILE_ERROR_MESSAGE,
  USERS_UPDATED
} from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import CommonTable from '../../../components/CommonTable';
import CommonPagination from '../../../components/CommonPagination';
import { currentActiveTabStep, toast } from '../../../common/utils';
import SearchComponent from '../../../components/SearchComponent';

const UpdatedAccountsInFile = () => {
  const {
    state: { isTestModeOn, isAutoUpdateOn, currentlyUploadedFile, currentStep },
    dispatch
  } = useContext(AppContext);

  const history = useHistory();
  const [existingAccounts, setExistingAccounts] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [usersToUpdate, setUsersToUpdate] = useState([]);
  const [updateUsersLoading, setUpdateUserLoading] = useState(false);
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const currentActiveTab = currentActiveTabStep();

  const fetchUpdatedUsers = async (searchValue = '') => {
    if (currentlyUploadedFile) {
      setLoading(true);
      api
        ?.post(
          `/file/user-info?skip=${skip}&limit=${RECORDS_PER_PAGE}&isTestMode=${
            isTestModeOn?.[currentActiveTabStep()]
          }&isAutoUpdate=${
            isAutoUpdateOn?.[currentActiveTabStep()]
          }&oldUserWithNewInfo=true${
            searchValue !== '' ? `&searchValue=${searchValue}` : ''
          }`,
          {
            fileLocationUrl: currentlyUploadedFile
          }
        )
        ?.then((response) => {
          setPaginationProp({
            ...paginationProp,
            total: response?.data?.data?.count
          });
          setExistingAccounts(response?.data?.data?.userInfoData);
          setLoading(false);
        })
        ?.catch((error) => {
          setLoading(false);
          return error;
        });
    } else {
      toast({
        message: UPLOAD_FILE_ERROR_MESSAGE,
        type: TOAST_TYPES.ERROR
      });
      dispatch({
        type: 'SET_CURRENT_STEP',
        data: { ...currentStep, [currentActiveTab]: 2 }
      });
      history?.push(ROUTES?.UPLOAD_FILE);
    }
  };

  useEffect(() => {
    fetchUpdatedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  const handleNextClick = () => {
    dispatch({
      type: 'SET_CURRENT_STEP',
      data: { ...currentStep, [currentActiveTab]: 2 }
    });
    history?.push(ROUTES?.FILE_UPLOAD_NEW_ACCOUNTS);
  };

  const handleUpdateExistingUsers = () => {
    setUpdateUserLoading(true);
    if (!isTestModeOn?.[currentActiveTabStep()]) {
      api
        ?.post(
          `/file/user-update?isTestMode=${
            isTestModeOn?.[currentActiveTabStep()]
          }&isAutoUpdate=${isAutoUpdateOn?.[currentActiveTabStep()]}`,
          {
            fileLocationUrl: currentlyUploadedFile,
            emailForUpdateUsers: usersToUpdate
          }
        )
        ?.then(() => {
          setUpdateUserLoading(false);
          setUsersToUpdate([]);
          handleNextClick();
        })
        ?.catch((error) => {
          setUsersToUpdate([]);
          setUpdateUserLoading(false);
          return error;
        });
    } else {
      setUsersToUpdate([]);
      setUpdateUserLoading(false);
      toast({
        message: USERS_UPDATED,
        type: TOAST_TYPES.SUCCESS
      });
      handleNextClick();
    }
  };

  const handleTableChange = (currentPage) => {
    setSkip((currentPage - 1) * RECORDS_PER_PAGE);
    setPaginationProp({ ...paginationProp, current: currentPage });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Full Name',
      key: 'Full Name',
      ...SearchComponent(
        'Full Name',
        setSearchText,
        fetchUpdatedUsers,
        searchText
      ),
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'Title',
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Email',
      dataIndex: 'Current Email Address',
      key: 'Current Email Address',
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Select all',
      width: '11%',
      align: 'right'
    },
    Table?.SELECTION_COLUMN
  ];

  const rowSelection = {
    selectedRowKeys: usersToUpdate,
    onChange: (selectedKeys) => {
      setUsersToUpdate(selectedKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record?.name === 'Disabled User',
      name: record?.name
    })
  };
  return (
    <div>
      <CommonTable
        rowKey={(e) => e?.['Current Email Address']}
        columns={columns}
        data={existingAccounts}
        loading={loading}
        rowSelection={{ type: 'checkBox', ...rowSelection }}
      />
      <div className="d-flex justify-between flex-wrap mt-8">
        <CommonPagination
          size="small"
          total={paginationProp?.total}
          onChange={handleTableChange}
          current={paginationProp?.current}
        />
        <div className="d-flex flex-wrap mt-8">
          <CommonButton
            type="ghost"
            className="mr-10"
            onClick={handleNextClick}
            disabled={updateUsersLoading}
          >
            Skip
          </CommonButton>
          <CommonButton
            type="primary"
            onClick={handleUpdateExistingUsers}
            loading={updateUsersLoading}
            disabled={usersToUpdate?.length <= 0}
          >
            Update & Continue
          </CommonButton>
        </div>
      </div>
    </div>
  );
};

export default UpdatedAccountsInFile;
