import React, { useEffect, useState } from 'react';
import api from '../../../common/api';
import CommonTable from '../../../components/CommonTable';
import CommonPagination from '../../../components/CommonPagination';
import { RECORDS_PER_PAGE, SUCCESS } from '../../../common/constants';

const SuccessfulSyncLog = ({ syncLog }) => {
  const [loading, setLoading] = useState(false);
  const [inactiveAccounts, setInactiveAccounts] = useState(null);
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const [skip, setSkip] = useState(0);
  const fetchSyncsLogById = () => {
    setLoading(true);
    if (syncLog?.status === SUCCESS)
      api
        ?.get(
          // eslint-disable-next-line no-underscore-dangle
          `ftp/get-user-cron-details?jobId=${syncLog?._id}&skip=${skip}&limit=${RECORDS_PER_PAGE}`
        )
        ?.then((response) => {
          setInactiveAccounts(response?.data?.data?.orgUser);
          setPaginationProp({
            ...paginationProp,
            total: response?.data?.data?.count
          });
          setLoading(false);
        })
        ?.catch((error) => {
          setLoading(false);
          return error;
        });
  };

  useEffect(() => {
    fetchSyncsLogById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  const columns = [
    {
      title: 'Name',
      dataIndex: ['fileRecordData', 'Full Name'],
      key: 'Full Name',
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Title',
      dataIndex: ['fileRecordData', 'Title'],
      key: 'Current Email Address',
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Email',
      dataIndex: ['fileRecordData', 'Current Email Address'],
      key: 'Current Email Address',
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Status',
      dataIndex: 'operation',
      key: 'operation',
      render: (record) => {
        return record ? <span className="font-active">{record}</span> : '-';
      }
    }
  ];

  const handleTableChange = (currentPage) => {
    setSkip((currentPage - 1) * RECORDS_PER_PAGE);
    setPaginationProp({ ...paginationProp, current: currentPage });
  };

  return (
    <div className="synclog-table">
      {syncLog?.status === SUCCESS ? (
        <>
          <CommonTable
            rowKey={(e) => e}
            columns={columns}
            data={inactiveAccounts}
            loading={loading}
            className="column-light"
          />
          <div className="d-flex justify-between flex-wrap mt-8">
            <CommonPagination
              size="small"
              total={paginationProp?.total}
              onChange={handleTableChange}
              current={paginationProp?.current}
            />
          </div>
        </>
      ) : (
        <span className="d-flex justify-center sync-heading text-align-center">
          {syncLog?.error?.errorMessage || 'Sync failed'}
        </span>
      )}
    </div>
  );
};

export default SuccessfulSyncLog;
