import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process?.env?.REACT_APP_FIREBASE_API_KEY,
  authDomain: process?.env?.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process?.env?.REACT_APP_FIREBASE_DB_URL,
  projectId: process?.env?.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process?.env?.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process?.env?.REACT_APP_FIREBASE_MESSAGE_ID,
  appId: process?.env?.REACT_APP_FIREBASE_APP_ID
};

export default firebaseConfig;
