/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  LOGIN: '/login',
  RESET: '/forgot-password',
  CHANGE: '/reset',
  VERIFY: '/verify',
  AZURE_ACTIVE_DIRECTORY: '/active-directory',
  SECURITY_GROUPS: '/active-directory/security-groups',
  UPDATE_EXISTING_ACCOUNTS: '/active-directory/update-existing-accounts',
  NEW_ACCOUNTS: '/active-directory/new-accounts',
  DEACTIVATE_ACCOUNTS: '/active-directory/deactivate-accounts',
  INACTIVE_ACCOUNTS: '/active-directory/inactive-accounts',
  ACTIVE_DIRECTORY_SYNC_COMPLETE: '/active-directory/sync-successful',
  SYNC_ACTIVE_DIRECTORY: '/active-directory/sync-active-directory',
  REMOTE_SERVER_ACCESS: '/remote-server-access',
  UPLOAD_FILE: '/upload-file',
  FILE_UPLOAD_EXISTING_ACCOUNTS: '/upload-file/update-existing-accounts',
  FILE_UPLOAD_NEW_ACCOUNTS: '/upload-file/new-accounts',
  FILE_UPLOAD_INACTIVE_ACCOUNTS: '/upload-file/inactive-accounts',
  FILE_UPLOAD_SYNC_COMPLETE: '/upload-file/sync-successful',
  FTP_EXISTING_ACCOUNTS: '/remote-server-access/update-existing-accounts',
  FTP_NEW_ACCOUNTS: '/remote-server-access/new-accounts',
  FTP_INACTIVE_ACCOUNTS: '/remote-server-access/inactive-accounts',
  FTP_SYNC_SUCCESSFUL: '/remote-server-access/sync-successful',
  AZURE_CALLBACK: '/azure/callback'
};

export const ALL_SECURITY_GROUPS_ID = '008287df-1b2a-4f1b-a6e9-68c2e08g74521';

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  AZURE_ACTIVE_DIRECTORY: 'Azure Active Directory',
  REMOTE_SERVER_ACCESS: 'Remote Server Access',
  UPLOAD_FILE: 'Upload a File'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const USER = 'USER';
export const AZURE_ACCESS_TOKEN = 'AZURE_ACCESS_TOKEN';
export const AZURE_USER = 'AZURE_USER';

/* Localstorage */

export const CURRENT_STEP = 'CURRENT_STEP';
export const TEST_MODE = 'TEST_MODE';
export const AUTO_UPDATE = 'AUTO_UPDATE';
export const TEMPLATE_FILE = '../assets/files/template.xlsx';
export const RECORDS_PER_PAGE = 25;
export const CURRENT_SECURITY_GROUP = 'CURRENT_SECURITY_GROUP';
export const WC_ORDER_IN_PROGRESS = 'WC_ORDER_IN_PROGRESS';
export const REQUIRED_CREDITS_DATA = 'REQUIRED_CREDITS_DATA';
export const ORDER_CONFIG_FOR_AUTO_SYNC = 'ORDER_CONFIG_FOR_AUTO_SYNC';
export const CURRENTLY_UPLOADED_FILE = 'CURRENTLY_UPLOADED_FILE';
export const ACTIVE_DIRECTORY_CURRENT_PATH = 'ACTIVE_DIRECTORY_CURRENT_PATH';
export const REMOTE_SERVER_CURRENT_PATH = 'REMOTE_SERVER_CURRENT_PATH';
export const UPLOAD_FILE_CURRENT_PATH = 'UPLOAD_FILE_CURRENT_PATH';
export const FTP_INPUT = 'FTP_INPUT';
export const FTP_UPLOADED_FILE = 'FTP_UPLOADED_FILE';
export const FTP_AUTO_SYNC = 'FTP_AUTO_SYNC';
export const CONNECTION_REQUEST = 'CONNECTION_REQUEST';

export const ACCESSORIES = {
  NFCSmartButton: 'NFC Smart Button',
  NFCKeyFob: 'NFC Key Fob',
  CustomNFCKeyFob: 'Custom NFC Key Fob',
  smartButtonAndKeyFob: 'key_fob+smart_button'
};

/* sidebar tabs */
export const ACTIVE_TABS = {
  activeDirectory: 'activeDirectory',
  remoteServerAccess: 'remoteServerAccess',
  uploadFile: 'uploadFile'
};

// sync status
export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export const PENDING = 'PENDING';

export const CARD_TYPES = {
  USER_LICENSE: 'User License',
  PLASTIC: 'Plastic Card',
  WOOD: 'wood',
  METAL: 'metal'
};

export const ADD_ONS = {
  CRMIntegrationAndAutomation: 'CRM integration & Automation',
  DataEnrichement: 'Data Enrichement',
  CustomPackage: 'Custom Package'
};

export const WOOCOMMERCE_PRODUCT_IDS = {
  customDesignedCard: 'MCC',
  userLicense: 'MUL',
  NFCSmartButton: 'NFC-SB',
  NFCKeyFob: 'NFC-KF',
  AutomationAndCRM: 'MAC-Y',
  DataEnrichment: 'MDE-Y',
  customDigi: 'CDP'
};

export const WOOCOMMERCE_CUSTOM_CARD_IDS = {
  plastic: 'MTC-P',
  wood: 'MTC-W',
  metal: 'MTC-M'
};

export const TOAST_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning'
};

export const SYNC_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const MOBILO_PRODUCTS = {
  mobiloCardCustom: 'mobilo_card_custom',
  mobiloWood: 'mobilo_wood',
  mobiloMetal: 'mobilo_metal',
  team: 'TEAM',
  apiAuto: 'API_AUTO',
  clearBit: 'CLEARBIT',
  customDigi: 'CUSTOM_DIGI',
  smartButton: 'smart_button',
  keyFob: 'key_fob',
  keyFobSmartButton: 'key_fob_smart_button'
};

// card colors
export const DARK = 'Dark';
export const LIGHT = 'Light';
export const BLACK = 'Black';
export const GOLD = 'Gold';
export const SILVER = 'Silver';

// toast messages
export const UPLOAD_FILE_ERROR_MESSAGE = 'Please upload file';
export const WC_ORDER_SUCCESS_MESSAGE =
  'Your order purchase was successfully done!';
export const WC_ORDER_FAILED_MESSAGE = 'Your order purchase is failed!';
export const ORG_ADMIN_ERROR_MESSAGE = 'This user is not an organization admin';
export const FTP_DISCONNECTED = 'Ftp connection disconnected';
export const USERS_DELETED = 'Users deleted successfully';
export const NO_NEW_ACCOUNTS = 'No new accounts found';
export const USERS_UPDATED = 'Users updated successfully';
export const SOMETHING_WENT_WRONG = 'Something went wrong';

export const ALL_SECURITY_GROUPS = 'ALL';
/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const REDIRECT_URL = {
  MOBILO_REDIRECT: 'https://mobilocard.com/'
};

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  IP_ADDRESS: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
};

export const MSAL_SCOPES =
  'email profile offline_access group.read.all user.read user.read.all';
