import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { ACTIVE_TABS, MODULES, ROUTES } from '../../../common/constants';
import Logout from '../../../modules/auth/Logout';
import { AppContext } from '../../../AppContext';
import ActiveDirectoryLogo from '../../../assets/svg/active-directory.svg';
import RemoteAccessLogo from '../../../assets/svg/remote-access.svg';
import UploadFile from '../../../assets/svg/upload-file.svg';
import GoBack from '../../../assets/svg/go-back.svg';
import { findRouteByStep } from '../../../common/utils';

const { Sider } = Layout;

function Sidebar({ location: { pathname }, history }) {
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const {
    state: { currentStep }
  } = useContext(AppContext);

  const getCurrentNav = (path) => {
    if (path === ROUTES?.AZURE_ACTIVE_DIRECTORY) {
      return ACTIVE_TABS?.activeDirectory;
    }
    // eslint-disable-next-line no-restricted-globals, no-undef
    if (path === ROUTES?.REMOTE_SERVER_ACCESS) {
      return ACTIVE_TABS?.remoteServerAccess;
    }
    // eslint-disable-next-line no-restricted-globals, no-undef
    if (path === ROUTES?.UPLOAD_FILE) {
      return ACTIVE_TABS?.uploadFile;
    }
  };
  const onMenuSelect = (e) => {
    const currentIndexRoute = getCurrentNav(e?.key);
    const redirectPath = findRouteByStep(
      currentIndexRoute,
      currentStep?.[currentIndexRoute]
    );
    history?.push(redirectPath);
  };

  const currentPath = `/${pathname?.split('/')?.[1]}`;
  const userName = currentUser?.userDetails?.['Full Name'];

  const handleGoBack = () => {
    // eslint-disable-next-line no-undef
    window?.open(process?.env?.REACT_APP_MOBILO_TEAMS_REDIRECT, '_self');
  };
  return (
    <Sider trigger={null}>
      <div className="gx-layout-sider-header">
        <div className="gx-linebar" />
        <div
          className="d-flex flex-vertical pointer"
          onClick={() => history?.push(ROUTES?.MAIN)}
        >
          <span className="sider-header-text">HR Integration</span>
          <span className="gx-fs-11">{userName || ''}</span>
        </div>
      </div>
      <div className="gx-sidebar-content">
        <Menu
          theme="lite"
          mode="inline"
          selectedKeys={[currentPath]}
          defaultSelectedKeys={[ROUTES?.MAIN]}
          onSelect={onMenuSelect}
        >
          <Menu.Item key={ROUTES?.AZURE_ACTIVE_DIRECTORY}>
            <img
              src={ActiveDirectoryLogo}
              alt="active-directory-logo"
              className="mr-10"
            />
            <span>{MODULES?.AZURE_ACTIVE_DIRECTORY}</span>
          </Menu.Item>
          <Menu.Item key={ROUTES?.REMOTE_SERVER_ACCESS}>
            <img
              src={RemoteAccessLogo}
              alt="remote-access-logo"
              className={`mr-10 ${
                currentPath === ROUTES?.REMOTE_SERVER_ACCESS &&
                'sidebar-icon-selected'
              } `}
            />
            <span>{MODULES?.REMOTE_SERVER_ACCESS}</span>
          </Menu.Item>
          <Menu.Item key={ROUTES?.UPLOAD_FILE}>
            <img
              src={UploadFile}
              alt="upload-file-logo"
              className={`mr-10 ${
                currentPath === ROUTES?.UPLOAD_FILE && 'sidebar-icon-selected'
              } `}
            />
            <span>{MODULES?.UPLOAD_FILE}</span>
          </Menu.Item>
        </Menu>
        <div className="sidebar-footer">
          <div
            className="mb-17 pointer d-flex justify-end align-center"
            onClick={handleGoBack}
          >
            <img src={GoBack} alt="go-back" className="mr-10" />
            <span className="font-16">Go back</span>
          </div>
          <Logout />
        </div>
      </div>
    </Sider>
  );
}

export default withRouter(Sidebar);
