import React from 'react';
import SuccessIcon from '../../../assets/svg/order-confirmed.svg';
import '../../ActiveDirectory/components/SyncSuccessful.less';
import UploadFile from './UploadFile';

const FileUploadSyncSuccess = () => {
  return (
    <div className="sync-successful-main">
      <span className="d-flex justify-center sync-heading text-align-center">
        Your Mobilo Team has Been Successfully Synced
      </span>
      <div className="d-flex justify-center success-icon">
        <img src={SuccessIcon} alt="success-icon" />
      </div>
      <UploadFile />
    </div>
  );
};

export default FileUploadSyncSuccess;
