import React from 'react';
import { useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import CommonButton from '../../../components/CommonButton';
import { loginRequest } from '../../../authConfig';
import { AZURE_ACCESS_TOKEN, ROUTES } from '../../../common/constants';
import './SyncActiveDirectory.less';

const SyncActiveDirectory = () => {
  const history = useHistory();
  const { instance } = useMsal();

  const handleSync = () => {
    // eslint-disable-next-line no-undef
    if (localStorage?.getItem(AZURE_ACCESS_TOKEN))
      history?.push(ROUTES?.SECURITY_GROUPS);
    else
      instance
        ?.handleRedirectPromise()
        ?.then(
          instance?.loginRedirect(loginRequest)?.catch((error) => {
            return error;
          })
        )
        ?.catch((error) => {
          return error;
        });
  };

  return (
    <div className="sync-directory-main">
      <CommonButton type="primary" onClick={handleSync}>
        Sync
      </CommonButton>
    </div>
  );
};

export default SyncActiveDirectory;
