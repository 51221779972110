import React from 'react';

const RemoteServerEmptyScreen = ({ emptySynclogs }) => {
  return (
    <div className="sync-successful-main">
      <span className="d-flex justify-center sync-heading text-align-center">
        {emptySynclogs
          ? 'No sync logs found for past three days'
          : 'Please start an auto sync or upload a file'}
      </span>
    </div>
  );
};

export default RemoteServerEmptyScreen;
