import { message } from 'antd';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../AppContext';
import api from '../common/api';

import {
  ACCESSORIES,
  ADD_ONS,
  CARD_TYPES,
  MOBILO_PRODUCTS,
  REQUIRED_CREDITS_DATA,
  TOKEN,
  WC_ORDER_FAILED_MESSAGE,
  WC_ORDER_SUCCESS_MESSAGE,
  WOOCOMMERCE_CUSTOM_CARD_IDS,
  WOOCOMMERCE_PRODUCT_IDS
} from '../common/constants';
import { getSocket } from '../Socket';
import CommonButton from './CommonButton';
import CommonModal from './CommonModal';
import OrderConfirmation from './OrderConfirmation';

const NeededCredits = ({
  neededCreditsVisible = false,
  setNeededCreditsVisible,
  requiredCredits,
  fetchNeededCredits,
  handleConfigurationClose
}) => {
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [addCreditsLoading, setAddCreditsLoading] = useState(false);

  const {
    state: { currentUser },
    dispatch
  } = useContext(AppContext);
  const authentication = getAuth();

  const orderUUID = uuidv4();
  const wooCommerceOrderInfo = [];
  const getWooCommerceOrderInfo = () => {
    if (requiredCredits?.needCredits) {
      if (requiredCredits?.cardType === MOBILO_PRODUCTS?.mobiloWood) {
        wooCommerceOrderInfo?.push({
          sku: WOOCOMMERCE_CUSTOM_CARD_IDS?.wood,
          quantity: requiredCredits?.needCredits
        });
      } else if (requiredCredits?.cardType === MOBILO_PRODUCTS?.mobiloMetal) {
        wooCommerceOrderInfo?.push({
          sku: WOOCOMMERCE_CUSTOM_CARD_IDS?.metal,
          quantity: requiredCredits?.needCredits
        });
      } else if (
        requiredCredits?.cardType === MOBILO_PRODUCTS?.mobiloCardCustom
      ) {
        wooCommerceOrderInfo?.push({
          sku: WOOCOMMERCE_CUSTOM_CARD_IDS?.plastic,
          quantity: requiredCredits?.needCredits
        });
      }
    }

    // eslint-disable-next-line array-callback-return
    requiredCredits?.licenses?.map((license) => {
      if (license?.needLicenseCount > 0) {
        if (license?.licenseType === MOBILO_PRODUCTS?.clearBit) {
          wooCommerceOrderInfo?.push({
            sku: WOOCOMMERCE_PRODUCT_IDS?.DataEnrichment,
            quantity: license?.needLicenseCount
          });
        }
        if (license?.licenseType === MOBILO_PRODUCTS?.apiAuto) {
          wooCommerceOrderInfo?.push({
            sku: WOOCOMMERCE_PRODUCT_IDS?.AutomationAndCRM,
            quantity: license?.needLicenseCount
          });
        }

        if (license?.licenseType === MOBILO_PRODUCTS?.customDigi) {
          wooCommerceOrderInfo?.push({
            sku: WOOCOMMERCE_PRODUCT_IDS?.customDigi,
            quantity: license?.needLicenseCount
          });
        }
        if (license?.licenseType === MOBILO_PRODUCTS?.team) {
          wooCommerceOrderInfo?.push({
            sku: WOOCOMMERCE_PRODUCT_IDS?.userLicense,
            quantity: license?.needLicenseCount
          });
        }
      }
    });

    if (Object?.keys(requiredCredits?.accessories)?.length) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        requiredCredits?.accessories?.hasOwnProperty(MOBILO_PRODUCTS?.keyFob) &&
        requiredCredits?.accessories?.key_fob > 0
      ) {
        wooCommerceOrderInfo?.push({
          sku: WOOCOMMERCE_PRODUCT_IDS?.NFCKeyFob,
          quantity: requiredCredits?.accessories?.key_fob
        });
      }

      if (
        // eslint-disable-next-line no-prototype-builtins
        requiredCredits?.accessories?.hasOwnProperty(
          MOBILO_PRODUCTS?.smartButton
        ) &&
        requiredCredits?.accessories?.smart_button > 0
      ) {
        wooCommerceOrderInfo?.push({
          sku: WOOCOMMERCE_PRODUCT_IDS?.NFCSmartButton,
          quantity: requiredCredits?.accessories?.smart_button
        });
      }
    }
  };

  const handleAddCredits = () => {
    getWooCommerceOrderInfo();
    setAddCreditsLoading(true);

    api
      ?.post(`credits/create-order`, {
        orderInfo: wooCommerceOrderInfo,
        uuid: orderUUID,
        userEmail: currentUser?.userDetails?.user,
        timestamp: moment(),
        orderCompleted: false
      })
      ?.then(() => {
        onAuthStateChanged(authentication, (user) => {
          if (user) {
            user?.getIdToken()?.then((token) => {
              // eslint-disable-next-line no-undef
              window?.open(
                `${
                  process?.env?.REACT_APP_BUY_MOBILO_CHECKOUT
                  // eslint-disable-next-line no-undef
                }?id=${orderUUID}&token=${
                  // eslint-disable-next-line no-undef
                  token || localStorage?.getItem(TOKEN)
                }&region=${
                  process?.env?.REACT_APP_SERVER_URL?.includes('eu.hr')
                    ? 'eu'
                    : 'ot'
                }`,
                '_blank'
              );
              setAddCreditsLoading(false);
              setNeededCreditsVisible(false);
              dispatch({ type: 'SET_WC_ORDER_STATUS', data: true });
              getSocket()?.on('order-status', (orderStatus) => {
                if (orderStatus) {
                  dispatch({ type: 'SET_WC_ORDER_STATUS', data: true });
                  message?.success(WC_ORDER_SUCCESS_MESSAGE);
                  fetchNeededCredits();
                } else {
                  message?.error(WC_ORDER_FAILED_MESSAGE);
                  dispatch({
                    type: 'SET_WC_ORDER_STATUS',
                    data: false
                  });
                }
              });
            });
          }
        });
      })
      ?.catch((error) => {
        return error;
      });
  };

  const handleNeedCreditsClose = () => {
    // eslint-disable-next-line no-undef
    localStorage?.removeItem(REQUIRED_CREDITS_DATA);
    handleConfigurationClose();
    setNeededCreditsVisible(false);
  };
  return (
    <div className="needed-credits-main">
      <CommonModal
        previewVisible={neededCreditsVisible}
        setPreviewVisible={setNeededCreditsVisible}
        onCancel={handleNeedCreditsClose}
        previewTitle="Add Credits"
        maskClosable={false}
      >
        <div className="pl-30">
          <span className="font-11">Needed credits to place your order:</span>
          {requiredCredits?.needCredits > 0 && (
            <div className="assigned-products-list mt-10">
              <span className="font-10-bold">Business Card:</span>
              <div className="assigned-products-list font-10 mt-6">
                <span className="font-red mr-10">
                  {requiredCredits?.needCredits}
                </span>
                {requiredCredits?.cardType === MOBILO_PRODUCTS?.mobiloWood && (
                  <span>{capitalize(CARD_TYPES?.WOOD)} Card</span>
                )}

                {requiredCredits?.cardType === MOBILO_PRODUCTS?.mobiloMetal && (
                  <span>{capitalize(CARD_TYPES?.METAL)} Card</span>
                )}

                {requiredCredits?.cardType ===
                  MOBILO_PRODUCTS?.mobiloCardCustom && (
                  <span>{CARD_TYPES?.PLASTIC}</span>
                )}
              </div>
            </div>
          )}

          {requiredCredits?.licenses &&
            !Object?.values(requiredCredits?.licenses)?.every((value) => {
              return value?.needLicenseCount <= 0;
            }) && (
              <div className="assigned-products-list mt-10">
                <span className="font-10-bold">Add Ons:</span>

                {requiredCredits?.licenses?.map((license) => {
                  return (
                    license?.needLicenseCount > 0 &&
                    license?.needLicenseCount !== null && (
                      <div
                        key={license?.licenseType}
                        className="assigned-products-list font-10 mt-6"
                      >
                        <span className="font-red mr-10">
                          {license?.needLicenseCount}
                        </span>
                        {license?.licenseType === MOBILO_PRODUCTS?.clearBit && (
                          <span>{ADD_ONS?.DataEnrichement}</span>
                        )}
                        {license?.licenseType === MOBILO_PRODUCTS?.apiAuto && (
                          <span>{ADD_ONS?.CRMIntegrationAndAutomation}</span>
                        )}
                        {license?.licenseType ===
                          MOBILO_PRODUCTS?.customDigi && (
                          <span>{ADD_ONS?.CustomPackage}</span>
                        )}

                        {license?.licenseType === MOBILO_PRODUCTS?.team && (
                          <span>{CARD_TYPES?.USER_LICENSE}</span>
                        )}
                      </div>
                    )
                  );
                })}
              </div>
            )}
          {requiredCredits?.accessories &&
            !Object?.values(requiredCredits?.accessories)?.every((value) => {
              return value === 0;
            }) && (
              <div className="assigned-products-list mt-10">
                <span className="font-10-bold">Accessories:</span>

                <div
                  key={requiredCredits?.accessories}
                  className="assigned-products-list font-10 mt-6"
                >
                  {
                    // eslint-disable-next-line no-prototype-builtins
                    requiredCredits?.accessories?.hasOwnProperty(
                      MOBILO_PRODUCTS?.keyFob
                    ) &&
                      requiredCredits?.accessories?.key_fob > 0 && (
                        <div>
                          <span className="font-red mr-10">
                            {requiredCredits?.accessories?.key_fob}
                          </span>

                          <span>{ACCESSORIES?.NFCKeyFob}</span>
                        </div>
                      )
                  }
                  {
                    // eslint-disable-next-line no-prototype-builtins
                    requiredCredits?.accessories?.hasOwnProperty(
                      MOBILO_PRODUCTS?.smartButton
                    ) &&
                      requiredCredits?.accessories?.smart_button > 0 && (
                        <div>
                          <span className="font-red mr-10">
                            {requiredCredits?.accessories?.smart_button}
                          </span>
                          <span>{ACCESSORIES?.NFCSmartButton}</span>
                        </div>
                      )
                  }
                  {
                    // eslint-disable-next-line no-prototype-builtins
                    requiredCredits?.accessories?.hasOwnProperty(
                      ACCESSORIES?.smartButtonAndKeyFob
                    ) &&
                      requiredCredits?.accessories?.[
                        ACCESSORIES?.smartButtonAndKeyFob
                      ] > 0 && (
                        <div>
                          <span className="font-red mr-10">
                            {
                              requiredCredits?.accessories?.[
                                ACCESSORIES?.smartButtonAndKeyFob
                              ]
                            }
                          </span>

                          <span>{ACCESSORIES?.CustomNFCKeyFob}</span>
                        </div>
                      )
                  }
                </div>
              </div>
            )}
        </div>
        <div className="modal-footer">
          <CommonButton
            key="submit"
            type="primary"
            onClick={handleAddCredits}
            loading={addCreditsLoading}
          >
            Add Credit
          </CommonButton>
        </div>
      </CommonModal>
      <OrderConfirmation
        confirmationVisible={confirmationVisible}
        setConfirmationVisible={setConfirmationVisible}
      />
    </div>
  );
};

export default NeededCredits;
