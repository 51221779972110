import React, { useContext, useEffect, useState } from 'react';
import { Card, Spin } from 'antd';
import CommonButton from '../../../components/CommonButton';
import api from '../../../common/api';
import './AvailableCredits.less';
import { AppContext } from '../../../AppContext';

const AvailableCredits = () => {
  const [creditsData, setCreditsData] = useState({});
  const {
    dispatch,
    state: { availableCredits, availableCreditsLoading }
  } = useContext(AppContext);

  // eslint-disable-next-line no-undef
  const refetchCredits = JSON?.parse(localStorage?.getItem('REFETCH_CREDITS'));
  const fetchAvailableCredits = () => {
    dispatch({
      type: 'SET_AVAILABLE_CREDITS_LOADING',
      data: true
    });
    api
      ?.get('credits/get')
      ?.then((response) => {
        dispatch({
          type: 'SET_AVAILABLE_CREDITS',
          data: response?.data?.data
        });
        dispatch({
          type: 'SET_AVAILABLE_CREDITS_LOADING',
          data: false
        });
      })
      ?.catch((error) => {
        dispatch({
          type: 'SET_AVAILABLE_CREDITS_LOADING',
          data: false
        });
        return error;
      });
  };

  useEffect(() => {
    setCreditsData({
      business: [
        {
          card: 'Plastic finish',
          credits: availableCredits?.mobilo_card_custom?.card_only || 0
        },
        {
          card: 'Wood finish',
          credits: availableCredits?.mobilo_wood?.card_only || 0
        },
        {
          card: 'Metal finish',
          credits: availableCredits?.mobilo_metal?.card_only || 0
        },
        {
          card: 'User License Only',
          credits: availableCredits?.user_license?.userLicenceCredits || 0
        }
      ],
      addOns: [
        {
          addOn: 'CRM integration & Automation',
          credits: availableCredits?.api_auto || 0
        },
        // Commenting this for now, might be used later
        // {
        //   addOn: 'Data Enrichement',
        //   credits: availableCredits?.clearbit || 0
        // },
        {
          addOn: 'Custom Package',
          credits: availableCredits?.custom_digi || 0
        }
      ],
      accessories: [
        {
          accessory: 'NFC Smart Button',
          credits: availableCredits?.totalAccessories?.smart_button || 0
        },
        {
          accessory: 'NFC Key Fob',
          credits: availableCredits?.totalAccessories?.key_fob || 0
        }
        // Commenting this for now, might be used later
        // {
        //   accessory: 'Custom NFC Key Fob',
        //   credits: availableCredits?.totalAccessories?.key_fob_smart_button || 0
        // }
      ]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCredits]);

  useEffect(() => {
    fetchAvailableCredits();
    // eslint-disable-next-line no-undef
    localStorage?.setItem('REFETCH_CREDITS', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchCredits]);

  const handleAddCredit = () => {
    // eslint-disable-next-line no-undef
    window?.open(process?.env?.REACT_APP_BUY_MOBILO, '_blank');
  };

  return (
    <Spin spinning={availableCreditsLoading}>
      <div className="available-credits-main">
        <Card
          className="available-credits-card"
          title="Available Credits"
          extra={
            <CommonButton type="primary" onClick={handleAddCredit}>
              Add credit
            </CommonButton>
          }
        >
          <div className="pb-10 font-12">Business cards:</div>
          {creditsData?.business?.map((data) => {
            return (
              <div className="credits-content" key={data?.card}>
                <div className="credits-text">
                  <div className="credits-width">{data?.credits}</div>
                  <div>{data?.card}</div>
                </div>
              </div>
            );
          })}

          <div className="pb-10 font-12">Add Ons:</div>
          {creditsData?.addOns?.map((data) => {
            return (
              <div className="credits-content" key={data?.addOn}>
                <div className="credits-text">
                  <div className="credits-width">{data?.credits}</div>
                  <div>{data?.addOn}</div>
                </div>
              </div>
            );
          })}
          <div className="pb-10 font-12">Accessories:</div>
          {creditsData?.accessories?.map((data) => {
            return (
              <div className="credits-content" key={data?.accessory}>
                <div className="credits-text">
                  <div className="credits-width">{data?.credits}</div>
                  <div>{data?.accessory}</div>
                </div>
              </div>
            );
          })}
        </Card>
      </div>
    </Spin>
  );
};

export default AvailableCredits;
