import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Button, message } from 'antd';
import { AppContextProvider } from './AppContext';
import reportWebVitals from './reportWebVitals';
import Routes from './Routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { msalConfig } from './authConfig';
import './index.css';
import './styles/main.less';

const msalInstance = new PublicClientApplication(msalConfig);
Sentry?.init({
  dsn: process?.env?.REACT_APP_SENTRY_DSN,
  environment: process?.env?.REACT_APP_ENV,
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <AppContextProvider>
    <MsalProvider instance={msalInstance}>
      <Routes />
    </MsalProvider>
  </AppContextProvider>,
  // eslint-disable-next-line no-undef
  document?.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration?.register({
  onUpdate: (registration) => {
    if (registration && registration?.waiting) {
      message?.info({
        content: (
          <>
            New version available! Click Reload to get the latest version.
            <Button
              className="ml-1 mb-0"
              type="link"
              onClick={() => {
                registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
                // eslint-disable-next-line no-undef
                window?.location.reload();
              }}
            >
              Reload
            </Button>
          </>
        ),
        duration: 0
      });
    }
  }
});

reportWebVitals();
