import React from 'react';
import { Table } from 'antd';
import { RECORDS_PER_PAGE } from '../common/constants';
import './CommonTable.less';

const CommonTable = (props) => {
  const {
    columns = [],
    data = [],
    onChange,
    loadingData = false,
    tableClassName = '',
    ...rest
  } = props;

  return (
    <div className="common-table-wrapper">
      <Table
        columns={columns}
        bordered={false}
        dataSource={data}
        className={tableClassName}
        onChange={onChange}
        pagination={false}
        defaultPageSize={RECORDS_PER_PAGE}
        loading={{
          spinning: loadingData,
          size: 'large'
        }}
        {...rest}
      />
    </div>
  );
};

export default CommonTable;
