import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CommonButton from './CommonButton';
import CommonModal from './CommonModal';
import { AppContext } from '../AppContext';
import OrderConfirmed from '../assets/svg/order-confirmed.svg';
import { currentActiveTabStep, findRouteByStep } from '../common/utils';

const OrderConfirmation = ({
  confirmationVisible = false,
  setConfirmationVisible,
  fetchNewUsers
}) => {
  const {
    dispatch,
    state: { currentStep }
  } = useContext(AppContext);
  const history = useHistory();
  const currentActiveTab = currentActiveTabStep();
  const handleContinue = () => {
    dispatch({
      type: 'SET_CURRENT_STEP',
      data: { ...currentStep, [currentActiveTab]: 3 }
    });
    history?.push(findRouteByStep(currentActiveTab, 3));
  };

  const handleModalClose = () => {
    setConfirmationVisible(false);
    fetchNewUsers();
  };

  return (
    <div className="confirmation-main">
      <CommonModal
        previewVisible={confirmationVisible}
        setPreviewVisible={setConfirmationVisible}
        previewTitle="Confirmation"
        onCancel={handleModalClose}
      >
        <div className="d-flex justify-center">
          <img src={OrderConfirmed} alt="order-confirmed" />
        </div>
        <div className="d-flex justify-center">
          <span className="font-10 mt-20">
            You order has been placed succesfully!
          </span>
        </div>
        <div className="modal-footer">
          <CommonButton
            key="submit"
            className="mr-5"
            type="ghost"
            onClick={handleModalClose}
          >
            Add More
          </CommonButton>

          <CommonButton key="submit" type="primary" onClick={handleContinue}>
            Continue
          </CommonButton>
        </div>
      </CommonModal>
    </div>
  );
};

export default OrderConfirmation;
