import React, { useEffect, useContext } from 'react';
import { Button, Card } from 'antd';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { loginRequest } from '../../../authConfig';
import { AppContext } from '../../../AppContext';
import CommonButton from '../../../components/CommonButton';
import { ROUTES } from '../../../common/constants';

const AzureAuthentication = () => {
  const { dispatch, getCurrentAzureUser } = useContext(AppContext);
  const { instance, accounts, inProgress } = useMsal();
  const currentAzureUser = getCurrentAzureUser();

  const history = useHistory();

  const handleLogin = async () => {
    instance
      ?.handleRedirectPromise()
      ?.then(
        instance?.loginRedirect(loginRequest)?.catch((error) => {
          return error;
        })
      )
      ?.catch((error) => {
        return error;
      });
  };

  const handleLogout = async () => {
    instance
      ?.handleRedirectPromise()
      ?.then(
        await instance?.logoutPopup()?.catch((error) => {
          return error;
        })
      )
      ?.catch((error) => {
        return error;
      });
    dispatch({ type: 'AZURE_LOGOUT' });
    history?.push(ROUTES?.AZURE_ACTIVE_DIRECTORY);
  };

  useEffect(() => {
    if (inProgress === InteractionStatus?.None && accounts?.length > 0) {
      const tokenRequest = {
        ...loginRequest,
        account: accounts[0]
      };
      if (accounts?.length > 0) {
        instance
          ?.acquireTokenSilent(tokenRequest)
          ?.then((response) => {
            const user = get(response, 'account');
            dispatch({ type: 'SET_AZURE_TOKEN', data: response?.accessToken });
            dispatch({
              type: 'SET_AZURE_USER',
              data:
                user && Object?.keys(user)?.length
                  ? JSON?.stringify(user)
                  : null
            });
          })
          ?.catch(async (error) => {
            instance?.acquireTokenPopup(tokenRequest)?.then((response) => {
              dispatch({
                type: 'SET_AZURE_TOKEN',
                data: response?.accessToken
              });
              dispatch({ type: 'SET_AZURE_USER', data: response?.account });
            });
            throw error;
          });
      } else {
        instance
          ?.handleRedirectPromise()
          ?.then(
            instance?.loginRedirect(loginRequest)?.catch((error) => {
              return error;
            })
          )
          ?.catch((error) => {
            return error;
          });
      }
    }
  }, [inProgress, accounts, instance, dispatch]);

  return (
    <div className="mb-17">
      <Card
        className="available-credits-card"
        title="Microsoft Azure account"
        extra={
          Object?.keys(currentAzureUser)?.length > 0 && (
            <Button
              type="danger"
              className="common-button-red"
              onClick={handleLogout}
            >
              Sign Out
            </Button>
          )
        }
      >
        {Object?.keys(currentAzureUser)?.length > 0 ? (
          <>
            <div>
              <span className="font-12-light">Hello </span>
              <span className="font-12">{currentAzureUser?.name}!</span>
            </div>
            <div className="font-10">ID: {currentAzureUser?.username}</div>
          </>
        ) : (
          <div className="d-flex justify-center">
            <CommonButton type="primary" onClick={handleLogin}>
              Sign in
            </CommonButton>
          </div>
        )}
      </Card>
    </div>
  );
};

export default AzureAuthentication;
