import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Radio, Table } from 'antd';
import { useMsal } from '@azure/msal-react';
import api from '../../../common/api';
import CommonTable from '../../../components/CommonTable';
import CommonButton from '../../../components/CommonButton';
import { AppContext } from '../../../AppContext';
import {
  ALL_SECURITY_GROUPS,
  ALL_SECURITY_GROUPS_ID,
  RECORDS_PER_PAGE,
  ROUTES
} from '../../../common/constants';
import CommonPagination from '../../../components/CommonPagination';
import { loginRequest } from '../../../authConfig';
import { currentActiveTabStep } from '../../../common/utils';
import SearchComponent from '../../../components/SearchComponent';

const SelectSecurityGroups = () => {
  const history = useHistory();
  const {
    state: { isAutoUpdateOn, currentSecurityGroup, isTestModeOn, currentStep },
    dispatch
  } = useContext(AppContext);
  const [groups, setGroups] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateUsersLoading, setUpdateUserLoading] = useState(false);

  const [allAccountsChecked, setAllAccountsChecked] = useState(true);
  const [securityGroup, setSecurityGroup] = useState([]);
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const [searchText, setSearchText] = useState('');
  const [skip, setSkip] = useState(0);
  const { instance, accounts } = useMsal();
  const tokenRequest = {
    ...loginRequest,
    account: accounts?.[0]
  };
  const currentActiveTab = currentActiveTabStep();

  const fetchData = (searchValue = '') => {
    setLoading(true);
    if (accounts?.length > 0) {
      instance
        ?.acquireTokenSilent(tokenRequest)
        ?.then((msalResponse) => {
          api
            ?.get(
              `active_directory/groups-new?limit=${RECORDS_PER_PAGE}&skip=${skip}${
                searchValue !== '' ? `&searchValue=${searchValue}` : ''
              }`,
              {
                headers: {
                  token_microsoft: msalResponse?.accessToken
                }
              }
            )
            ?.then((response) => {
              setGroups(response?.data?.data?.allGroups);
              setPaginationProp({
                ...paginationProp,
                total: response?.data?.data?.groupCount
              });
              dispatch({
                type: 'SET_CURRENT_SECURITY_GROUP',
                data: ALL_SECURITY_GROUPS
              });

              if (searchText === '') {
                if (response?.data?.data?.allGroups?.length > 0) {
                  setSecurityGroup(ALL_SECURITY_GROUPS_ID);
                } else {
                  dispatch({
                    type: 'SET_CURRENT_STEP',
                    data: { ...currentStep, [currentActiveTab]: 1 }
                  });
                  history?.push(ROUTES?.UPDATE_EXISTING_ACCOUNTS);
                }
              }

              setLoading(false);
            })
            ?.catch((error) => {
              setLoading(false);
              return error;
            });
        })
        ?.catch(async (error) => {
          instance?.acquireTokenPopup(tokenRequest)?.then((response) => {
            dispatch({
              type: 'SET_AZURE_TOKEN',
              data: response?.accessToken
            });
            dispatch({ type: 'SET_AZURE_USER', data: response?.account });
          });
          throw error;
        });
    } else {
      instance
        ?.handleRedirectPromise()
        ?.then(
          instance?.loginRedirect(loginRequest)?.catch((error) => {
            return error;
          })
        )
        ?.catch((error) => {
          return error;
        });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, accounts]);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...SearchComponent('name', setSearchText, fetchData, searchText)
    },
    {
      title: 'Object ID',
      dataIndex: 'id',
      key: 'id'
    },
    Table?.SELECTION_COLUMN
  ];

  const handleNextClick = () => {
    if (isAutoUpdateOn?.[currentActiveTabStep()]) {
      if (!isTestModeOn?.[currentActiveTabStep()]) {
        setUpdateUserLoading(true);
        instance
          ?.acquireTokenSilent(tokenRequest)
          ?.then((msalResponse) => {
            api
              ?.put(
                currentSecurityGroup === ALL_SECURITY_GROUPS
                  ? `/active_directory/update-user?getIsTestMode=${
                      isTestModeOn?.[currentActiveTabStep()]
                    }&autoUpdate=${isAutoUpdateOn?.[currentActiveTabStep()]}`
                  : `/active_directory/update-user?groupId=${currentSecurityGroup}&getIsTestMode=${
                      isTestModeOn?.[currentActiveTabStep()]
                    }&autoUpdate=${isAutoUpdateOn?.[currentActiveTabStep()]}`,
                `active_directory/groups-new?limit=${RECORDS_PER_PAGE}&skip=${skip}`,
                {
                  headers: {
                    token_microsoft: msalResponse?.accessToken
                  }
                }
              )
              ?.then(() => {
                setLoading(false);
                setUpdateUserLoading(false);
                dispatch({
                  type: 'SET_CURRENT_STEP',
                  data: { ...currentStep, [currentActiveTab]: 2 }
                });
                history?.push(ROUTES?.NEW_ACCOUNTS);
              })
              ?.catch((error) => {
                setUpdateUserLoading(false);
                history?.push(ROUTES?.NEW_ACCOUNTS);
                return error;
              });
          })
          ?.catch(async (error) => {
            instance?.acquireTokenPopup(tokenRequest)?.then((response) => {
              dispatch({
                type: 'SET_AZURE_TOKEN',
                data: response?.accessToken
              });
              dispatch({ type: 'SET_AZURE_USER', data: response?.account });
            });
            throw error;
          });
      } else {
        history?.push(ROUTES?.NEW_ACCOUNTS);
      }
    } else {
      dispatch({
        type: 'SET_CURRENT_STEP',
        data: { ...currentStep, activeDirectory: 1 }
      });

      history?.push(ROUTES?.UPDATE_EXISTING_ACCOUNTS);
    }
  };

  const handlePaginationChange = (currentPage) => {
    setSkip((currentPage - 1) * RECORDS_PER_PAGE);
    setPaginationProp({ ...paginationProp, current: currentPage });
  };

  const rowSelection = {
    selectedRowKeys: securityGroup,
    onChange: (selectedKeys) => {
      setAllAccountsChecked(false);
      setSecurityGroup(selectedKeys);
      dispatch({ type: 'SET_CURRENT_SECURITY_GROUP', data: selectedKeys?.[0] });
    },
    getCheckboxProps: (record) => ({
      disabled: record?.name === 'Disabled User',
      name: record?.name
    })
  };

  return (
    <div>
      <CommonTable
        sticky
        scroll={{ y: 'calc(100vh - 305px)' }}
        rowKey={(e) => e?.id}
        columns={columns || []}
        data={groups || []}
        loading={loading}
        rowSelection={{ type: 'radio', ...rowSelection }}
        summary={() =>
          groups?.length > 0 && (
            <Table.Summary fixed="top">
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={2}>
                  All accounts
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Radio
                    checked={allAccountsChecked}
                    onChange={() => {
                      setAllAccountsChecked(true);
                      setSecurityGroup([]);
                      dispatch({
                        type: 'SET_CURRENT_SECURITY_GROUP',
                        data: ALL_SECURITY_GROUPS
                      });
                    }}
                  />
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )
        }
      />
      <div className="d-flex justify-between align-center">
        <CommonPagination
          size="small"
          total={paginationProp?.total}
          onChange={handlePaginationChange}
          current={paginationProp?.current}
        />
        <div className="d-flex justify-end mt-8">
          <CommonButton
            type="primary"
            onClick={handleNextClick}
            loading={updateUsersLoading}
            disabled={loading}
          >
            Next
          </CommonButton>
        </div>
      </div>
    </div>
  );
};

export default SelectSecurityGroups;
