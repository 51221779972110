import { useMsal } from '@azure/msal-react';
import { Table } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { loginRequest } from '../../../authConfig';
import api from '../../../common/api';
import {
  ALL_SECURITY_GROUPS,
  NO_NEW_ACCOUNTS,
  RECORDS_PER_PAGE,
  ROUTES,
  TOAST_TYPES
} from '../../../common/constants';
import { currentActiveTabStep, toast } from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonPagination from '../../../components/CommonPagination';
import CommonTable from '../../../components/CommonTable';
import NewAccountConfiguration from '../../../components/NewAccountConfiguration';
import SearchComponent from '../../../components/SearchComponent';

const NewAccounts = () => {
  const {
    dispatch,
    state: { currentSecurityGroup, isTestModeOn, currentStep }
  } = useContext(AppContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [ignoreUsersLoading, setIgnoreUsersLoading] = useState(false);
  const [newAccounts, setNewAccounts] = useState(null);
  const [configurationModalVisible, setConfigurationModalVisible] = useState(
    false
  );
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [getOldUsers, setGetOldUsers] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const { instance, accounts } = useMsal();
  const tokenRequest = {
    ...loginRequest,
    account: accounts[0]
  };
  const [skip, setSkip] = useState(0);
  const source = axios?.CancelToken?.source();
  const currentActiveTab = currentActiveTabStep();
  const handleSkip = () => {
    dispatch({
      type: 'SET_CURRENT_STEP',
      data: { ...currentStep, [currentActiveTab]: 3 }
    });

    history?.push(ROUTES?.DEACTIVATE_ACCOUNTS);
  };
  const fetchNewUsers = (searchValue = '') => {
    setLoading(true);
    if (accounts?.length > 0) {
      instance
        ?.acquireTokenSilent(tokenRequest)
        ?.then((msalResponse) => {
          api
            ?.get(
              currentSecurityGroup === ALL_SECURITY_GROUPS
                ? `active_directory/new-users?getOldUsers=${getOldUsers}&skip=${skip}&limit=${RECORDS_PER_PAGE}${
                    searchValue !== '' ? `&searchValue=${searchValue}` : ''
                  }`
                : `active_directory/new-users?groupId=${currentSecurityGroup}&getOldUsers=${getOldUsers}&skip=${skip}&limit=${RECORDS_PER_PAGE}${
                    searchValue !== '' ? `&searchValue=${searchValue}` : ''
                  }`,
              {
                cancelToken: source?.token,
                headers: {
                  token_microsoft: msalResponse?.accessToken
                }
              }
            )
            ?.then((response) => {
              setLoading(false);
              if (response)
                if (
                  response?.data?.data?.totalUserCount > 0 ||
                  searchValue !== ''
                ) {
                  setNewAccounts(response?.data?.data?.newusers);
                  setPaginationProp({
                    ...paginationProp,
                    total: response?.data?.data?.totalUserCount
                  });
                } else {
                  toast({
                    message: NO_NEW_ACCOUNTS,
                    type: TOAST_TYPES.ERROR
                  });
                  handleSkip();
                }
            })
            ?.catch((error) => {
              setLoading(false);
              return error;
            });
        })
        ?.catch(async (error) => {
          instance?.acquireTokenPopup(tokenRequest)?.then((response) => {
            dispatch({
              type: 'SET_AZURE_TOKEN',
              data: response?.accessToken
            });
            dispatch({ type: 'SET_AZURE_USER', data: response?.account });
          });
          throw error;
        });
    } else {
      instance
        ?.handleRedirectPromise()
        ?.then(
          instance?.loginRedirect(loginRequest)?.catch((error) => {
            return error;
          })
        )
        ?.catch((error) => {
          return error;
        });
    }
  };

  useEffect(() => {
    return () => {
      source?.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowSelection = {
    renderCell(checked, record, index, node) {
      const status = <span className="font-active">Added</span>;
      if (record?.isAdded) {
        return status;
      }
      return node;
    },
    checkStrictly: true,
    selectedRowKeys: selectedUsers,
    getCheckboxProps: (record) => ({
      disabled: record?.isAdded
    }),
    onChange: (rows) => {
      setSelectedUsers(rows);
    }
  };

  useEffect(() => {
    fetchNewUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      ...SearchComponent(
        'displayName',
        setSearchText,
        fetchNewUsers,
        searchText
      ),
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Title',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      render: (jobTitle) => {
        return jobTitle || '-';
      }
    },
    {
      title: 'Email',
      dataIndex: 'userPrincipalName',
      key: 'userPrincipalName',
      render: (userPrincipalName) => {
        return userPrincipalName || '-';
      }
    },
    {
      title: 'Select all',
      width: '8%',
      align: 'right'
    },
    Table?.SELECTION_COLUMN
  ];

  const handleIgnoreUsers = () => {
    setIgnoreUsersLoading(true);
    if (!isTestModeOn?.[currentActiveTabStep()]) {
      api
        ?.post('/organization/ignore_users', {
          users: _.map(selectedUsers, 'userPrincipalName')
        })
        ?.then(() => {
          setLoading(false);
          setSelectedUsers([]);
          setIgnoreUsersLoading(false);
          fetchNewUsers();
        })
        ?.catch((error) => {
          setSelectedUsers([]);
          setIgnoreUsersLoading(false);
          return error;
        });
    } else {
      setIgnoreUsersLoading(false);
      setSelectedUsers([]);
      toast({ message: 'Users updated successfully', type: 'success' });
    }
  };

  const handlePaginationChange = (currentPage) => {
    setSkip((currentPage - 1) * RECORDS_PER_PAGE);
    setPaginationProp({ ...paginationProp, current: currentPage });
  };

  return (
    <div className="new-accounts-main">
      <CommonTable
        rowKey={(e) => e}
        columns={columns}
        data={newAccounts}
        loading={loading}
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        paginationConfig={paginationProp}
      />
      <div className="d-flex justify-between align-center">
        <CommonPagination
          size="small"
          total={paginationProp?.total}
          onChange={handlePaginationChange}
          current={paginationProp?.current}
        />
        <div className="d-flex flex-wrap mt-8">
          <CommonButton
            disabled={!selectedUsers || selectedUsers?.length < 1 || loading}
            type="ghost"
            loading={ignoreUsersLoading}
            className="button-outline-red mr-5 mb-6"
            onClick={handleIgnoreUsers}
          >
            Don’t show again
          </CommonButton>
          <CommonButton type="ghost" className="mr-5 mb-6" onClick={handleSkip}>
            skip
          </CommonButton>

          <CommonButton
            disabled={!selectedUsers || selectedUsers?.length < 1 || loading}
            type="primary"
            className="mr-5 mb-6"
            onClick={() => setConfigurationModalVisible(true)}
          >
            Add
          </CommonButton>
        </div>
      </div>

      <NewAccountConfiguration
        configurationModalVisible={configurationModalVisible}
        setConfigurationModalVisible={setConfigurationModalVisible}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        fetchNewUsers={fetchNewUsers}
        setGetOldUsers={setGetOldUsers}
      />
    </div>
  );
};

export default NewAccounts;
