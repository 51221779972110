import React, { useContext, useEffect, useState } from 'react';
import { Card, Checkbox, Divider, Form, Radio, Spin, Switch } from 'antd';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import './SettingsCard.less';
import CommonButton from '../../../components/CommonButton';
import {
  ACCESSORIES,
  ACTIVE_TABS,
  ADD_ONS,
  BLACK,
  CARD_TYPES,
  DARK,
  GOLD,
  LIGHT,
  MOBILO_PRODUCTS,
  ORDER_CONFIG_FOR_AUTO_SYNC,
  SILVER
} from '../../../common/constants';
import api from '../../../common/api';
import { currentActiveTabStep, toast } from '../../../common/utils';

let data = {
  businessCard: '',
  addOns: [],
  accessories: []
};

const SettingsCard = () => {
  const {
    dispatch,
    state: { isTestModeOn, isAutoUpdateOn, currentUser }
  } = useContext(AppContext);
  const location = useLocation();
  const currentPath = location?.pathname?.split('/')?.[1];
  const handleAutoUpdate = () => {
    dispatch({
      type: 'TOGGLE_AUTO_UPDATE',
      data: {
        ...isAutoUpdateOn,
        [currentActiveTabStep()]: !isAutoUpdateOn?.[currentActiveTabStep()]
      }
    });
  };
  const [cardType, setCardType] = useState('');
  const [cardSubtype, setCardSubtype] = useState('');
  const [sendLoginInstructions, setSendLoginInstructions] = useState(false);
  const [sendProductInfo, setSendProductInfo] = useState(false);
  const [userConfigLoading, setUserConfigLoading] = useState(false);
  const [defaultLoading, setDefaultLoading] = useState(false);
  const requiredCreditsData = {
    cards: '',
    type: '',
    licenseType: []
  };

  const cardTypes = {
    userLicense: 'User License',
    plasticCard: 'Plastic Card',
    woodCard: ['Dark', 'Light'],
    metalCard: ['Black', 'Gold', 'Silver']
  };
  const accessories = ['NFC Smart Button', 'NFC Key Fob'];

  const addOns = [
    'CRM integration & Automation',
    // Commenting this for now, might be used later
    // 'Data Enrichement',
    'Custom Package'
  ];

  const selectAddOns = (value) => {
    data = { ...data, addOns: value };
  };

  const selectAccesories = (value) => {
    data = { ...data, accessories: value };
  };

  const onChange = (event) => {
    const type = event?.target?.value;
    setCardType(type);
    setCardSubtype();
  };
  const onChangeNest = (event) => {
    const nestedValue = event?.target?.value;
    setCardSubtype(nestedValue);
    if (nestedValue?.includes('wood')) {
      setCardType('wood');
    } else if (nestedValue?.includes('metal')) {
      setCardType('metal');
    }
  };
  const disablePlaceOrder = () => {
    if (cardType === '') {
      return true;
    }
    if (!cardType && !cardSubtype) {
      return true;
    }
    if (cardType === 'plastic' || cardType === 'license') {
      return false;
    }
    if (cardType === 'wood' || cardType === 'metal') {
      if (!cardSubtype) {
        return true;
      }
      return false;
    }
  };

  const userConfigDefaultValue = (userDetailConfig) => {
    setSendLoginInstructions(
      currentUser?.allowInstructionAndTagging
        ? userDetailConfig?.sendLoginCredential
        : false
    );
    setSendProductInfo(
      currentUser?.allowInstructionAndTagging
        ? userDetailConfig?.sendProductUpdates
        : false
    );
    if (userDetailConfig?.cardType === MOBILO_PRODUCTS?.mobiloWood) {
      setCardType(CARD_TYPES?.WOOD);
    } else if (
      userDetailConfig?.cardType === MOBILO_PRODUCTS?.mobiloCardCustom
    ) {
      setCardType(CARD_TYPES?.PLASTIC);
    } else if (userDetailConfig?.cardType === MOBILO_PRODUCTS?.mobiloMetal) {
      setCardType(CARD_TYPES?.METAL);
    } else if (userDetailConfig?.licenseType?.includes(MOBILO_PRODUCTS?.team)) {
      setCardType(CARD_TYPES?.USER_LICENSE);
    }

    // setting default card color
    if (userDetailConfig?.cardColor !== '') {
      if (userDetailConfig?.cardColor === DARK) {
        setCardSubtype(DARK);
      } else if (userDetailConfig?.cardColor === LIGHT) {
        setCardSubtype(LIGHT);
      } else if (userDetailConfig?.cardColor === BLACK) {
        setCardSubtype(BLACK);
      } else if (userDetailConfig?.cardColor === GOLD) {
        setCardSubtype(GOLD);
      } else if (userDetailConfig?.cardColor === SILVER) {
        setCardSubtype(SILVER);
      }
    }

    // setting default license
    if (userDetailConfig?.licenseType?.length > 0) {
      if (userDetailConfig?.licenseType?.includes(MOBILO_PRODUCTS?.apiAuto)) {
        data = {
          ...data,
          addOns: [...data?.addOns, ADD_ONS?.CRMIntegrationAndAutomation]
        };
      }

      if (
        userDetailConfig?.licenseType?.includes(MOBILO_PRODUCTS?.customDigi)
      ) {
        data = {
          ...data,
          addOns: [...data?.addOns, ADD_ONS?.CustomPackage]
        };
      }
    }

    // setting default accessories
    if (userDetailConfig?.cardVariation !== 'card_only') {
      if (
        userDetailConfig?.cardVariation?.includes(MOBILO_PRODUCTS?.smartButton)
      ) {
        data = {
          ...data,
          accessories: [...data?.accessories, ACCESSORIES?.NFCSmartButton]
        };
      }
      if (userDetailConfig?.cardVariation?.includes(MOBILO_PRODUCTS?.keyFob)) {
        data = {
          ...data,
          accessories: [...data?.accessories, ACCESSORIES?.NFCKeyFob]
        };
      }
    }
  };
  const getUserConfig = () => {
    setDefaultLoading(true);
    api
      ?.get('profile/get-profile')
      ?.then((response) => {
        if (response?.data?.data) {
          dispatch({
            type: 'SET_CURRENT_USER',
            data: response?.data?.data
          });
        }
        if (response?.data?.data?.isUserConfig) {
          if (currentActiveTabStep() === ACTIVE_TABS?.remoteServerAccess) {
            dispatch({
              type: 'TOGGLE_TEST_MODE',
              data: {
                ...isTestModeOn,
                [currentActiveTabStep()]: response?.data?.data?.userDetailConfig
                  ?.isTestMode
              }
            });
          }
          Promise.all([
            userConfigDefaultValue(response?.data?.data?.userDetailConfig)
          ]).then(() => {
            setDefaultLoading(false);
          });
        } else {
          setDefaultLoading(false);
        }
      })
      ?.catch((error) => {
        setDefaultLoading(false);
        return error;
      });
  };

  const disableOrder = disablePlaceOrder();
  const handleUserConfig = ({ testModeUpdated }) => {
    const orderConfig = JSON?.parse(
      // eslint-disable-next-line no-undef
      localStorage?.getItem(ORDER_CONFIG_FOR_AUTO_SYNC)
    );
    api({
      method: `${currentUser?.isUserConfig ? 'put' : 'post'}`,
      url: 'automated_sync/new_user_config',
      data: {
        cardColor: cardSubtype,
        cardType: orderConfig?.cards,
        cardVariation: orderConfig?.type,
        defaultAdmin: currentUser?.userDetails?.userId,
        isTestMode: testModeUpdated
          ? !isTestModeOn?.[currentActiveTabStep()]
          : isTestModeOn?.[currentActiveTabStep()],
        licenseType: orderConfig?.licenseType,
        sendLoginCredential: currentUser?.allowInstructionAndTagging
          ? sendLoginInstructions
          : false,
        sendProductUpdates: currentUser?.allowInstructionAndTagging
          ? sendProductInfo
          : false,
        id: currentUser?.isUserConfig ? currentUser?.userDetailConfig?.id : ''
      }
    })
      ?.then((response) => {
        dispatch({
          type: 'SET_WC_ORDER_STATUS',
          data: false
        });
        if (response) {
          getUserConfig();
        }
        setUserConfigLoading(false);
      })
      ?.catch((error) => {
        dispatch({
          type: 'SET_WC_ORDER_STATUS',
          data: false
        });
        setUserConfigLoading(false);
        return error;
      });
  };

  const handleTestMode = () => {
    if (currentActiveTabStep() === ACTIVE_TABS?.remoteServerAccess) {
      if (currentUser?.isUserConfig) {
        setDefaultLoading(true);
        handleUserConfig({ testModeUpdated: true });
      } else {
        toast({
          message:
            'Please confirm the standard user configuration below, in order to proceed with the Test Mode switched on',
          type: 'error'
        });
      }
    } else {
      dispatch({
        type: 'TOGGLE_TEST_MODE',
        data: {
          ...isTestModeOn,
          [currentActiveTabStep()]: !isTestModeOn?.[currentActiveTabStep()]
        }
      });
    }
  };
  const handleSubmit = () => {
    setUserConfigLoading(true);
    if (cardType !== null && cardType !== undefined) {
      if (cardType === CARD_TYPES?.PLASTIC) {
        requiredCreditsData.cards = MOBILO_PRODUCTS?.mobiloCardCustom;
      } else if (cardType === CARD_TYPES?.WOOD) {
        requiredCreditsData.cards = MOBILO_PRODUCTS?.mobiloWood;
      } else if (cardType === CARD_TYPES?.METAL) {
        requiredCreditsData.cards = MOBILO_PRODUCTS?.mobiloMetal;
      } else if (cardType === CARD_TYPES?.USER_LICENSE) {
        requiredCreditsData.licenseType = [
          ...requiredCreditsData?.licenseType,
          MOBILO_PRODUCTS?.team
        ];
      }
    }

    if (data?.addOns?.length > 0) {
      if (data?.addOns?.includes(ADD_ONS?.CRMIntegrationAndAutomation)) {
        requiredCreditsData.licenseType = [
          ...requiredCreditsData?.licenseType,
          MOBILO_PRODUCTS?.apiAuto
        ];
      }
      // Commenting this for now, might be used later
      // if (data?.addOns?.includes(ADD_ONS?.DataEnrichement)) {
      //   requiredCreditsData.licenseType = [
      //     ...requiredCreditsData?.licenseType,
      //     MOBILO_PRODUCTS?.clearBit
      //   ];
      // }

      if (data?.addOns?.includes(ADD_ONS?.CustomPackage)) {
        requiredCreditsData.licenseType = [
          ...requiredCreditsData?.licenseType,
          MOBILO_PRODUCTS?.customDigi
        ];
      }
    }

    if (cardType !== CARD_TYPES?.USER_LICENSE) {
      if (data?.accessories?.includes(ACCESSORIES?.NFCSmartButton)) {
        requiredCreditsData.type = MOBILO_PRODUCTS?.smartButton;
      }
      if (data?.accessories?.includes(ACCESSORIES?.NFCKeyFob)) {
        requiredCreditsData.type = MOBILO_PRODUCTS?.keyFob;
      }
      if (
        data?.accessories?.includes(ACCESSORIES?.NFCSmartButton) &&
        data?.accessories?.includes(ACCESSORIES?.NFCKeyFob)
      ) {
        requiredCreditsData.type = `${MOBILO_PRODUCTS?.keyFob}_${MOBILO_PRODUCTS?.smartButton}`;
      }
      if (
        !data?.accessories?.includes(ACCESSORIES?.NFCSmartButton) &&
        !data?.accessories?.includes(ACCESSORIES?.NFCKeyFob) &&
        cardType !== ''
      ) {
        requiredCreditsData.type = 'card_only';
      }
    }

    // eslint-disable-next-line no-undef
    localStorage?.setItem(
      ORDER_CONFIG_FOR_AUTO_SYNC,
      JSON?.stringify(requiredCreditsData)
    );
    handleUserConfig({ testModeUpdated: false });
  };

  useEffect(() => {
    getUserConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="settings-card-main mb-17">
      <Spin spinning={defaultLoading}>
        <Card
          title="Settings"
          className={`settings-card-main mb-17 ${
            currentPath === 'remote-server-access' ? 'heading-blue' : ''
          }`}
        >
          <div className="d-flex justify-between mlr-5">
            <span className="settings-text"> Test Mode </span>
            <Switch
              size="small"
              checked={isTestModeOn?.[currentActiveTabStep()]}
              onChange={handleTestMode}
            />
          </div>
          <Divider />
          {currentPath !== 'remote-server-access' ? (
            <div className="d-flex justify-between mlr-5 ">
              <span className="settings-text">
                Auto-Update existent accounts
              </span>
              <Switch
                size="small"
                checked={isAutoUpdateOn?.[currentActiveTabStep()]}
                onChange={handleAutoUpdate}
              />
            </div>
          ) : (
            <>
              <span className="font-12">New User Configuration</span>
              {!defaultLoading && (
                <Form name="validate_other">
                  <div className="new-configuration-main">
                    <span className="font-11">Assigned Products:</span>
                    <div className="assigned-products-list">
                      <span className="font-10-bold"> Business Card: </span>
                      <div className="pl-15">
                        <Form.Item>
                          <Radio.Group
                            onChange={onChange}
                            value={cardType}
                            disabled={userConfigLoading}
                          >
                            <Radio value={cardTypes?.userLicense}>
                              {cardTypes?.userLicense}
                            </Radio>
                            <Radio value={cardTypes?.plasticCard}>
                              {cardTypes?.plasticCard}
                            </Radio>
                            <Radio value="wood">
                              Wood Card
                              {cardType === 'wood' && (
                                <Radio.Group
                                  onChange={onChangeNest}
                                  value={cardSubtype}
                                  options={cardTypes?.woodCard}
                                  disabled={userConfigLoading}
                                />
                              )}
                            </Radio>
                            <Radio value="metal">
                              Metal Card
                              {cardType === 'metal' && (
                                <Radio.Group
                                  onChange={onChangeNest}
                                  value={cardSubtype}
                                  options={cardTypes?.metalCard}
                                  disabled={userConfigLoading}
                                />
                              )}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="assigned-products-list">
                      <span className="font-10-bold">Add Ons: (Optional) </span>
                      <div className="pl-15">
                        <Form.Item>
                          <Checkbox.Group
                            defaultValue={data?.addOns}
                            options={addOns}
                            onChange={selectAddOns}
                            disabled={userConfigLoading}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="assigned-products-list">
                      <span className="font-10-bold ">
                        Accessories: (Optional)
                      </span>
                      <div className="pl-15">
                        <Form.Item>
                          <Checkbox.Group
                            disabled={
                              cardType === cardTypes?.userLicense ||
                              userConfigLoading
                            }
                            options={accessories}
                            defaultValue={data?.accessories}
                            onChange={selectAccesories}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    {currentUser?.allowInstructionAndTagging && (
                      <>
                        <div>
                          <Checkbox
                            onChange={() =>
                              setSendLoginInstructions(!sendLoginInstructions)
                            }
                            defaultChecked={sendLoginInstructions}
                            disabled={userConfigLoading}
                          >
                            Send login instructions to the new user
                          </Checkbox>
                        </div>
                        <div>
                          <Checkbox
                            onChange={() =>
                              setSendProductInfo(!sendProductInfo)
                            }
                            defaultChecked={sendProductInfo}
                            className="mb-6"
                            disabled={userConfigLoading}
                          >
                            Send product tips, updates, and mobilo news to the
                            new users
                          </Checkbox>
                        </div>
                      </>
                    )}
                    <Form.Item>
                      <CommonButton
                        disabled={disableOrder}
                        key="submit"
                        type="primary"
                        className="m-auto"
                        onClick={handleSubmit}
                        loading={userConfigLoading}
                      >
                        Save
                      </CommonButton>
                    </Form.Item>
                  </div>
                </Form>
              )}
            </>
          )}
        </Card>
      </Spin>
    </div>
  );
};

export default SettingsCard;
