import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Stepper from '../../components/CommonStepper';
import TableHeader from '../../components/TableHeader';
import DeactivateAccounts from './components/DeactivateAccounts';
import InactiveAccounts from './components/InactiveAccounts';
import NewAccounts from './components/NewAccounts';
import SelectSecurityGroups from './components/SelectSecurityGroups';
import SyncActiveDirectory from './components/SyncActiveDirectory';
import UpdateExistingAccounts from './components/UpdateExistingAccounts';
import SyncSuccessful from './components/SyncSuccessful';
import Error404 from '../../Error404';

const ActiveDirectoryWrapper = () => {
  const { pathname } = useLocation();
  return (
    <div className="main-wrapper">
      {pathname?.includes(ROUTES?.AZURE_ACTIVE_DIRECTORY) && (
        <>
          <Stepper />
          <TableHeader />
        </>
      )}
      <Switch>
        <Route
          exact
          path={ROUTES?.AZURE_ACTIVE_DIRECTORY}
          component={SyncActiveDirectory}
        />
        <Route
          exact
          path={ROUTES?.SECURITY_GROUPS}
          component={SelectSecurityGroups}
        />
        <Route
          exact
          path={ROUTES?.UPDATE_EXISTING_ACCOUNTS}
          component={UpdateExistingAccounts}
        />
        <Route exact path={ROUTES?.NEW_ACCOUNTS} component={NewAccounts} />
        <Route
          exact
          path={ROUTES?.DEACTIVATE_ACCOUNTS}
          component={DeactivateAccounts}
        />
        <Route
          exact
          path={ROUTES?.INACTIVE_ACCOUNTS}
          component={InactiveAccounts}
        />
        <Route
          exact
          path={ROUTES?.ACTIVE_DIRECTORY_SYNC_COMPLETE}
          component={SyncSuccessful}
        />
        <Route path="*" exact component={Error404} />
      </Switch>
    </div>
  );
};

export default ActiveDirectoryWrapper;
