import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { AppContext } from '../../../AppContext';
import api from '../../../common/api';
import {
  ALL_SECURITY_GROUPS,
  RECORDS_PER_PAGE,
  ROUTES,
  TOAST_TYPES,
  USERS_UPDATED
} from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import CommonTable from '../../../components/CommonTable';
import CommonPagination from '../../../components/CommonPagination';
import { loginRequest } from '../../../authConfig';
import { currentActiveTabStep, toast } from '../../../common/utils';
import SearchComponent from '../../../components/SearchComponent';

const UpdateExistingAccounts = () => {
  const {
    state: { currentSecurityGroup, isTestModeOn, isAutoUpdateOn, currentStep },
    dispatch
  } = useContext(AppContext);

  const history = useHistory();
  const [existingAccounts, setExistingAccounts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [usersToUpdate, setUsersToUpdate] = useState([]);
  const [updateUsersLoading, setUpdateUserLoading] = useState(false);
  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1
  });
  const [searchText, setSearchText] = useState('');
  const { instance, accounts } = useMsal();
  const tokenRequest = {
    ...loginRequest,
    account: accounts[0]
  };
  const currentActiveTab = currentActiveTabStep();
  const fetchData = (searchValue = '') => {
    setLoading(true);
    if (accounts?.length > 0) {
      instance
        ?.acquireTokenSilent(tokenRequest)
        ?.then((msalResponse) => {
          api
            ?.get(
              currentSecurityGroup === ALL_SECURITY_GROUPS
                ? `active_directory/get-user-exists-with-newinfo?skip=${skip}&limit=${RECORDS_PER_PAGE}${
                    searchValue !== '' ? `&searchValue=${searchValue}` : ''
                  }`
                : `active_directory/get-user-exists-with-newinfo?groupId=${currentSecurityGroup}&skip=${skip}&limit=${RECORDS_PER_PAGE}${
                    searchValue !== '' ? `&searchValue=${searchValue}` : ''
                  }`,
              {
                headers: {
                  token_microsoft: msalResponse?.accessToken
                }
              }
            )
            ?.then((response) => {
              setLoading(false);
              setPaginationProp({
                ...paginationProp,
                total: response?.data?.data?.allreadyExistUserCount
              });
              setExistingAccounts(response?.data?.data?.oldUserWithNewInfo);
              setLoading(false);
            })
            ?.catch((error) => {
              setLoading(false);
              return error;
            });
        })
        ?.catch(async (error) => {
          instance?.acquireTokenPopup(tokenRequest)?.then((response) => {
            dispatch({
              type: 'SET_AZURE_TOKEN',
              data: response?.accessToken
            });
            dispatch({ type: 'SET_AZURE_USER', data: response?.account });
          });
          throw error;
        });
    } else {
      instance
        ?.handleRedirectPromise()
        ?.then(
          instance?.loginRedirect(loginRequest)?.catch((error) => {
            return error;
          })
        )
        ?.catch((error) => {
          return error;
        });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  const handleNextClick = () => {
    dispatch({
      type: 'SET_CURRENT_STEP',
      data: {
        ...currentStep,
        [currentActiveTab]: 2
      }
    });
    history?.push(ROUTES?.NEW_ACCOUNTS);
  };

  const handleUpdateExistingUsers = () => {
    setUpdateUserLoading(true);
    if (!isTestModeOn?.[currentActiveTabStep()]) {
      if (accounts?.length > 0) {
        instance
          ?.acquireTokenSilent(tokenRequest)
          ?.then((msalResponse) => {
            api
              ?.put(
                currentSecurityGroup === ALL_SECURITY_GROUPS
                  ? `/active_directory/update-user?getIsTestMode=${
                      isTestModeOn?.[currentActiveTabStep()]
                    }&autoUpdate=${isAutoUpdateOn?.[currentActiveTabStep()]}`
                  : `/active_directory/update-user?groupId=${currentSecurityGroup}&getIsTestMode=${
                      isTestModeOn?.[currentActiveTabStep()]
                    }&autoUpdate=${isAutoUpdateOn?.[currentActiveTabStep()]}`,
                {
                  emailForUpdateUsers: usersToUpdate
                },
                {
                  headers: {
                    token_microsoft: msalResponse?.accessToken
                  }
                }
              )
              ?.then(() => {
                setUsersToUpdate([]);
                setUpdateUserLoading(false);
                handleNextClick();
              })
              ?.catch((error) => {
                setUsersToUpdate([]);
                setUpdateUserLoading(false);
                return error;
              });
          })
          ?.catch(async (error) => {
            instance?.acquireTokenPopup(tokenRequest)?.then((response) => {
              dispatch({
                type: 'SET_AZURE_TOKEN',
                data: response?.accessToken
              });
              dispatch({ type: 'SET_AZURE_USER', data: response?.account });
            });
            throw error;
          });
      } else {
        instance
          ?.handleRedirectPromise()
          ?.then(
            instance?.loginRedirect(loginRequest)?.catch((error) => {
              return error;
            })
          )
          ?.catch((error) => {
            return error;
          });
      }
    } else {
      setUsersToUpdate([]);
      setUpdateUserLoading(false);
      toast({
        message: USERS_UPDATED,
        type: TOAST_TYPES.SUCCESS
      });
      handleNextClick();
    }
  };

  const handleTableChange = (currentPage) => {
    setSkip((currentPage - 1) * RECORDS_PER_PAGE);
    setPaginationProp({ ...paginationProp, current: currentPage });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      ...SearchComponent('displayName', setSearchText, fetchData, searchText),
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Title',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Email',
      dataIndex: 'userPrincipalName',
      key: 'userPrincipalName',
      render: (record) => {
        return record || '-';
      }
    },
    {
      title: 'Select all',
      width: '11%',
      align: 'right'
    },
    Table?.SELECTION_COLUMN
  ];

  const rowSelection = {
    selectedRowKeys: usersToUpdate,
    onChange: (selectedKeys) => {
      setUsersToUpdate(selectedKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record?.name === 'Disabled User',
      name: record?.name
    })
  };
  return (
    <div>
      <CommonTable
        rowKey={(e) => e?.userPrincipalName}
        columns={columns}
        data={existingAccounts}
        loading={loading}
        rowSelection={{ type: 'checkBox', ...rowSelection }}
      />
      <div className="d-flex justify-between flex-wrap mt-8">
        <CommonPagination
          size="small"
          total={paginationProp?.total}
          onChange={handleTableChange}
          current={paginationProp?.current}
        />
        <div className="d-flex flex-wrap mt-8">
          <CommonButton
            type="ghost"
            className="mr-10"
            onClick={handleNextClick}
            disabled={updateUsersLoading}
          >
            Skip
          </CommonButton>
          <CommonButton
            type="primary"
            onClick={handleUpdateExistingUsers}
            loading={updateUsersLoading}
            disabled={usersToUpdate?.length <= 0}
          >
            Update & Continue
          </CommonButton>
        </div>
      </div>
    </div>
  );
};

export default UpdateExistingAccounts;
