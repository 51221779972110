import React from 'react';
import { Result } from 'antd';
import { Link } from 'react-router-dom';

const Error404 = () => {
  return (
    <>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
      <p className="gx-text-center">
        <Link className="gx-btn gx-btn-primary" to="/">
          Home
        </Link>
      </p>
    </>
  );
};

export default Error404;
