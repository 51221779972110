import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AppContext } from '../AppContext';
import BackIcon from '../assets/svg/back.svg';
import ExitIcon from '../assets/svg/exit-icon.svg';
import { ACTIVE_TABS, CONNECTION_REQUEST, ROUTES } from '../common/constants';
import {
  currentActiveTabStep,
  findRouteByStep,
  getTableHeading
} from '../common/utils';
import api from '../common/api';

const TableHeader = () => {
  const {
    state: { currentStep, isAutoUpdateOn, currentUser },
    dispatch
  } = useContext(AppContext);

  const location = useLocation();
  const syncDirectory = location?.pathname?.split('/')?.[2];
  const currentActiveTab = currentActiveTabStep();

  const history = useHistory();
  const tableHeading = getTableHeading(currentStep?.[currentActiveTab]);
  const authentication = getAuth();

  const handleDisconnectServer = () => {
    // eslint-disable-next-line no-undef
    const disconnectPayload = JSON?.parse(
      // eslint-disable-next-line no-undef
      localStorage?.getItem(CONNECTION_REQUEST)
    );

    onAuthStateChanged(authentication, (user) => {
      if (user) {
        user?.getIdToken()?.then((token) => {
          api
            ?.post(
              `${process?.env?.REACT_APP_SERVER_URL}ftp/disconnect`,
              {
                ...disconnectPayload,
                isAutoSyncEnabled: false,
                orgId: currentUser?.userDetails?.orgId,
                id: currentUser?.userFtpDetail?.id
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            )
            .then(() => {
              dispatch({ type: 'SET_FTP_AUTO_SYNC', data: false });
            })
            .catch((error) => {
              return error;
            });
        });
      }
    });
  };
  const handleBackClick = () => {
    if (currentStep?.[currentActiveTab] === 3) {
      dispatch({
        type: 'SET_CURRENT_STEP',
        data: {
          ...currentStep,
          [currentActiveTab]: currentStep?.[currentActiveTab] - 1
        }
      });

      history?.push(
        findRouteByStep(currentActiveTab, currentStep?.[currentActiveTab] - 1)
      );
    } else if (currentStep?.[currentActiveTab] === 2) {
      if (isAutoUpdateOn?.[currentActiveTabStep()]) {
        dispatch({
          type: 'SET_CURRENT_STEP',
          data: {
            ...currentStep,
            [currentActiveTab]: 0
          }
        });
        history?.push(findRouteByStep(currentActiveTab, 0));
      } else {
        dispatch({
          type: 'SET_CURRENT_STEP',
          data: {
            ...currentStep,
            [currentActiveTab]: currentStep?.[currentActiveTab] - 1
          }
        });
        history?.push(
          findRouteByStep(currentActiveTab, currentStep?.[currentActiveTab] - 1)
        );
      }
    } else if (currentStep?.[currentActiveTab] === 1) {
      dispatch({
        type: 'SET_CURRENT_STEP',
        data: {
          ...currentStep,
          [currentActiveTab]: currentStep?.[currentActiveTab] - 1
        }
      });
      if (currentActiveTab === ACTIVE_TABS?.activeDirectory) {
        history?.push(ROUTES?.SECURITY_GROUPS);
      } else {
        history?.push(
          findRouteByStep(currentActiveTab, currentStep?.[currentActiveTab] - 1)
        );
      }

      if (currentActiveTab === ACTIVE_TABS?.remoteServerAccess) {
        handleDisconnectServer();
        dispatch({
          type: 'SET_FTP_INPUT',
          data: {}
        });
      }
    }
  };

  const handleExit = () => {
    if (currentActiveTab === ACTIVE_TABS?.remoteServerAccess) {
      handleDisconnectServer();
      dispatch({
        type: 'SET_FTP_INPUT',
        data: {}
      });
    }
    dispatch({
      type: 'SET_CURRENT_STEP',
      data: { ...currentStep, [currentActiveTab]: 0 }
    });
    history?.push(findRouteByStep(currentActiveTab, 0));
  };

  return (
    <>
      {syncDirectory !== undefined &&
        syncDirectory !== 'sync-successful' &&
        syncDirectory !== 'deactivate-accounts' && (
          <div className="table-header-main">
            <div
              className={`d-flex align-center pointer ${
                currentStep?.[currentActiveTab] === 0 && 'visibility-hidden'
              }`}
              onClick={handleBackClick}
            >
              <img src={BackIcon} alt="back" className="mr-10" />
              <span>Back</span>
            </div>

            <span className="table-heading">{tableHeading}</span>
            <div className="d-flex align-center pointer" onClick={handleExit}>
              <span className="mr-10">Exit</span>
              <img src={ExitIcon} alt="exit" />
            </div>
          </div>
        )}
    </>
  );
};

export default TableHeader;
