import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import CommonButton from '../../../components/CommonButton';
import CommonModal from '../../../components/CommonModal';
import CommonTable from '../../../components/CommonTable';
import './SelectFileModal.less';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { currentActiveTabStep } from '../../../common/utils';
import api from '../../../common/api';

const SelectFileModal = ({
  setSelectFileVisible,
  selectFileVisible,
  fileList,
  connectionRequest,
  handleDisconnectServer
}) => {
  const {
    dispatch,
    state: { currentStep, ftpInput }
  } = useContext(AppContext);
  const [selectedFile, setSelectedFile] = useState([]);
  const currentActiveTab = currentActiveTabStep();
  const [filePathLoading, setFilePathLoading] = useState(false);
  const columns = [
    {
      title: 'File name',
      dataIndex: 'file',
      key: 'file'
    },

    Table?.SELECTION_COLUMN
  ];
  const history = useHistory();

  const rowSelection = {
    selectedRowKeys: selectedFile,
    onChange: (selectedKeys) => {
      setSelectedFile(selectedKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record?.name === 'Disabled User',
      name: record?.name
    })
  };

  const handleContinue = () => {
    setFilePathLoading(true);
    api
      ?.post(`ftp/filepath `, {
        ftpInput: connectionRequest,
        fileName: selectedFile?.[0]
      })
      .then((response) => {
        if (response?.data?.data?.fileLocationUrl) {
          dispatch({
            type: 'SET_FTP_INPUT',
            data: {
              ...ftpInput,
              fileLocationUrl: response?.data?.data?.fileLocationUrl,
              fileName: selectedFile?.[0]
            }
          });
          dispatch({
            type: 'SET_CURRENT_STEP',
            data: { ...currentStep, [currentActiveTab]: 1 }
          });
          // eslint-disable-next-line no-param-reassign
          fileList = [];
          history?.push(ROUTES?.FTP_EXISTING_ACCOUNTS);
        }
        setFilePathLoading(false);
        setSelectFileVisible(false);
      })
      .catch((error) => {
        dispatch({
          type: 'SET_FTP_INPUT',
          data: {}
        });
        setFilePathLoading(false);
        return error;
      });
  };

  const handleClosePopup = () => {
    handleDisconnectServer({ disconnectOneTimeUpload: true });
    // eslint-disable-next-line no-param-reassign
    fileList = [];
    dispatch({
      type: 'SET_FTP_INPUT',
      data: {}
    });
    setSelectFileVisible(false);
  };
  return (
    <div>
      <CommonModal
        previewVisible={selectFileVisible}
        setPreviewVisible={setSelectFileVisible}
        previewTitle="Confirmation"
        className="file-modal-wrapper"
        onCancel={handleClosePopup}
        maskClosable={false}
      >
        <CommonTable
          rowKey={(e) => e?.file}
          columns={columns || []}
          data={fileList || []}
          rowSelection={{ type: 'radio', ...rowSelection }}
          scroll={{ y: '500px' }}
        />
        <div className="modal-footer">
          <CommonButton
            key="submit"
            type="primary"
            onClick={handleContinue}
            disabled={selectedFile?.length <= 0}
            loading={filePathLoading}
          >
            Continue
          </CommonButton>
        </div>
      </CommonModal>
    </div>
  );
};

export default SelectFileModal;
