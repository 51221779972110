import React, { useContext, useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { get } from 'lodash';
import { initializeApp } from 'firebase/app';
import { Button, Form, Input, message } from 'antd';
import * as Sentry from '@sentry/react';
import { AppContext } from '../../AppContext';
import {
  ORG_ADMIN_ERROR_MESSAGE,
  REDIRECT_URL,
  SOMETHING_WENT_WRONG,
  TOAST_TYPES
} from '../../common/constants';
import { formValidatorRules, toast } from '../../common/utils';
import firebaseConfig from '../../firebaseConfig';
import logo from '../../assets/logo.png';
import api from '../../common/api';

const { required, email } = formValidatorRules;
initializeApp(firebaseConfig);
const authentication = getAuth();

const Login = (props) => {
  const [loginLoading, setLoginLoading] = useState(false);
  const { initializeAuth, dispatch } = useContext(AppContext);
  function successCallback(accessToken, userData) {
    initializeAuth(accessToken, userData);
    props?.history?.replace('/');
  }

  const onFinish = async (values) => {
    setLoginLoading(true);
    signInWithEmailAndPassword(authentication, values?.email, values?.password)
      ?.then((response) => {
        const accessToken = get(response, '_tokenResponse.idToken');
        api
          ?.get('profile/get-profile')
          ?.then((res) => {
            if (res) {
              if (res?.data?.data?.userDetails?.isOrgAdmin) {
                successCallback(accessToken, res?.data?.data);
                setLoginLoading(false);
              } else {
                setLoginLoading(false);
                getAuth()
                  ?.signOut()
                  ?.then(() => dispatch({ type: 'LOGOUT' }));
                toast({
                  message: ORG_ADMIN_ERROR_MESSAGE,
                  type: TOAST_TYPES.ERROR
                });
              }
            } else {
              setLoginLoading(false);
              toast({
                message: SOMETHING_WENT_WRONG,
                type: TOAST_TYPES.ERROR
              });
            }
          })
          ?.catch((error) => {
            setLoginLoading(false);
            return error;
          });
      })
      ?.catch((error) => {
        Sentry.captureException(
          new Error(`[Response error]: Message: ${error}`)
        );
        setLoginLoading(false);
        if (error?.code === 'auth/network-request-failed') {
          message?.error('Please check your internet connectivity');
        } else if (error?.code === 'auth/wrong-password') {
          message?.error('Incorrect password');
        } else if (error?.code === 'auth/user-not-found') {
          message?.error('User not found');
        } else {
          message?.error('something went wrong');
        }
        return error;
      });
  };

  return (
    <div className="gx-login-container">
      <div wrapperClassName="gx-login-content">
        <div className="gx-login-header gx-text-center mb-0">
          <img src={logo} alt="mobilo-logo" className="mb-4 width-percent-40" />
        </div>
        <Form
          name="Login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item name="email" label="EMAIL" rules={[required, email]}>
            <Input
              placeholder="Enter your email address"
              className="form-input"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="PASSWORD"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" visibilityToggle />
          </Form.Item>
          <Form.Item className="gx-text-center d-flex justify-center">
            <Button
              className="login-button"
              htmlType="submit"
              loading={loginLoading}
            >
              LOG IN
            </Button>
          </Form.Item>
        </Form>

        <div className="flex-vertical">
          <div className="d-flex justify-center">
            Not account? Get mobilo cards!
          </div>
          <a
            className="d-flex justify-center"
            href={REDIRECT_URL?.MOBILO_REDIRECT}
          >
            {REDIRECT_URL?.MOBILO_REDIRECT}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
