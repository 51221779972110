import React, { useContext, useEffect } from 'react';
import {
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken
} from 'firebase/auth';
import { Card, Layout, Spin } from 'antd';
import * as Sentry from '@sentry/react';
import { initializeApp } from 'firebase/app';
import { useHistory, useLocation } from 'react-router-dom';
import MiddleLayout from './components/middle-layout';
import Sidebar from './components/sidebar/Sidebar';
import ContentRoutes from './ContentRoutes';
import firebaseConfig from '../firebaseConfig';
import {
  ORG_ADMIN_ERROR_MESSAGE,
  SOMETHING_WENT_WRONG,
  TOKEN
} from '../common/constants';
import './App.css';
import { AppContext } from '../AppContext';
import api from '../common/api';
import { initializeSocket } from '../Socket';
import { toast } from '../common/utils';

const { Content } = Layout;
initializeApp(firebaseConfig);
const authentication = getAuth();
onAuthStateChanged(authentication, (currentUser) => {
  // eslint-disable-next-line no-undef
  if (localStorage?.getItem(TOKEN))
    // eslint-disable-next-line no-undef
    localStorage?.setItem(TOKEN, currentUser?.accessToken);
});
const auth = getAuth();

const App = () => {
  useEffect(() => {
    initializeSocket();
  }, []);
  const {
    initializeAuth,
    dispatch,
    state: { wcOrderInProgress, FTPConnectionInProgress }
  } = useContext(AppContext);

  const history = useHistory();
  function successCallback(accessToken, userData) {
    initializeAuth(accessToken, userData);
    history?.replace('/');
  }
  const location = useLocation();
  const mobiloTeamsUserid = new URLSearchParams(location?.search).get('userid');

  useEffect(() => {
    if (mobiloTeamsUserid) {
      api
        ?.get(
          `${process?.env?.REACT_APP_SERVER_URL}profile/user-custom-token/${mobiloTeamsUserid}`
        )
        ?.then((response) => {
          const token = response?.data?.data;
          signInWithCustomToken(auth, token)
            ?.then((userCredential) => {
              const accessToken = userCredential?.user?.accessToken;
              api
                ?.get('profile/get-profile')
                ?.then((res) => {
                  if (res) {
                    if (res?.data?.data?.userDetails?.isOrgAdmin) {
                      successCallback(accessToken, res?.data?.data);
                    } else {
                      getAuth()
                        ?.signOut()
                        ?.then(() => dispatch({ type: 'LOGOUT' }));
                      toast({
                        message: ORG_ADMIN_ERROR_MESSAGE,
                        type: 'error'
                      });
                    }
                  } else {
                    toast({
                      message: SOMETHING_WENT_WRONG,
                      type: 'error'
                    });
                  }
                })
                ?.catch((error) => {
                  return error;
                });
            })
            ?.catch((error) => {
              Sentry.captureException(
                new Error(`[Response error]: Message: ${error}`)
              );
              return error;
            });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobiloTeamsUserid]);

  return (
    <Spin
      spinning={wcOrderInProgress || FTPConnectionInProgress}
      size="large"
      className="parent-spinner"
      tip={
        FTPConnectionInProgress
          ? 'Please wait while FTP connection is in progress!'
          : 'Please wait while your order is being processed!'
      }
    >
      <Layout className="gx-app-layout">
        <Sidebar />
        <div className="responsive-layout">
          <MiddleLayout />
          <Layout>
            <Card className="content-card card-height">
              <Content className="gx-layout-content">
                <ContentRoutes />
              </Content>
            </Card>
          </Layout>
        </div>
      </Layout>
    </Spin>
  );
};

export default App;
