import React, { useContext, useState } from 'react';
import { Radio, Form, Checkbox } from 'antd';
import CommonButton from './CommonButton';
import CommonModal from './CommonModal';
import NeededCredits from './NeededCredits';
import './NewAccountConfiguration.less';
import {
  ACCESSORIES,
  ADD_ONS,
  CARD_TYPES,
  MOBILO_PRODUCTS,
  REQUIRED_CREDITS_DATA
} from '../common/constants';
import api from '../common/api';
import { AppContext } from '../AppContext';
import OrderConfirmation from './OrderConfirmation';
import { currentActiveTabStep } from '../common/utils';

const NewAccountConfiguration = ({
  configurationModalVisible = false,
  setConfigurationModalVisible,
  selectedUsers,
  fetchNewUsers,
  setGetOldUsers,
  setSelectedUsers
}) => {
  const {
    state: { isTestModeOn, currentUser },
    dispatch
  } = useContext(AppContext);
  const [neededCreditsVisible, setNeededCreditsVisible] = useState(false);
  const [cardType, setCardType] = useState(null);
  const [creditsLoading, setCreditsLoading] = useState(false);
  const [deductCreditsLoading, setDeductCreditsLoading] = useState(false);
  const [cardSubtype, setCardSubtype] = useState();
  const [sendLoginInstructions, setSendLoginInstructions] = useState(false);
  const [sendProductInfo, setSendProductInfo] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const cardTypes = {
    userLicense: 'User License',
    plasticCard: 'Plastic Card',
    woodCard: ['Dark', 'Light'],
    metalCard: ['Black', 'Gold', 'Silver']
  };
  let requiredCreditsData = {
    cards: '',
    type: [],
    count: 0,
    licenseType: []
  };
  const currentActiveTab = currentActiveTabStep();

  const [requiredCredits, setRequiredCredits] = useState(null);

  const accessories = ['NFC Smart Button', 'NFC Key Fob'];
  const addOns = [
    'CRM integration & Automation',
    // Commenting this for now, might be used later
    // 'Data Enrichement',
    'Custom Package'
  ];
  const [data, setData] = useState({
    businessCard: '',
    addOns: null,
    accessories: null
  });

  const handleConfigurationClose = () => {
    setCardType(null);
    requiredCreditsData = {
      cards: '',
      type: [],
      count: 0,
      licenseType: []
    };

    setData({
      businessCard: '',
      addOns: null,
      accessories: null
    });
    setConfigurationModalVisible(false);
  };

  const fetchAvailableCredits = () => {
    dispatch({
      type: 'SET_AVAILABLE_CREDITS_LOADING',
      data: true
    });
    api
      ?.get('credits/get')
      ?.then((response) => {
        dispatch({ type: 'SET_AVAILABLE_CREDITS', data: response?.data?.data });
        dispatch({
          type: 'SET_AVAILABLE_CREDITS_LOADING',
          data: false
        });
      })
      ?.catch((error) => {
        dispatch({
          type: 'SET_AVAILABLE_CREDITS_LOADING',
          data: false
        });
        return error;
      });
  };
  const handleDeductCredits = () => {
    setDeductCreditsLoading(true);
    const creditsOrderInfo = JSON?.parse(
      // eslint-disable-next-line no-undef
      localStorage?.getItem(REQUIRED_CREDITS_DATA)
    );

    api
      ?.post(`credits/deduct-credits`, {
        cardType: creditsOrderInfo?.cards,
        addOns: creditsOrderInfo?.licenseType,
        variation: creditsOrderInfo?.type,
        cardColor: cardSubtype,
        employees: selectedUsers,
        sendLoginCredential: currentUser?.allowInstructionAndTagging
          ? sendLoginInstructions
          : false,
        sendProductUpdates: currentUser?.allowInstructionAndTagging
          ? sendProductInfo
          : false,
        isAzureSync: currentActiveTab === 'activeDirectory',
        isTestMode: isTestModeOn?.[currentActiveTabStep()]
      })
      ?.then((response) => {
        dispatch({
          type: 'SET_WC_ORDER_STATUS',
          data: false
        });
        setSelectedUsers([]);
        handleConfigurationClose();
        if (response) {
          setDeductCreditsLoading(false);
          setConfirmationVisible(true);
          setGetOldUsers(true);
          fetchAvailableCredits();
        } else {
          setDeductCreditsLoading(false);
        }
      })
      ?.catch((error) => {
        dispatch({
          type: 'SET_WC_ORDER_STATUS',
          data: false
        });
        handleConfigurationClose();
        setSelectedUsers([]);
        setDeductCreditsLoading(false);
        return error;
      });
  };

  const fetchNeededCredits = () => {
    setCreditsLoading(true);
    const creditsOrderInfo = JSON.parse(
      // eslint-disable-next-line no-undef
      localStorage?.getItem(REQUIRED_CREDITS_DATA)
    );
    api
      ?.post(`credits/needed/credits`, {
        cards: creditsOrderInfo?.cards,
        type: creditsOrderInfo?.type,
        count: creditsOrderInfo?.count,
        licenseType: creditsOrderInfo?.licenseType
      })
      ?.then((response) => {
        setCreditsLoading(false);
        if (response?.data?.data?.isCreditsNeeded) {
          handleConfigurationClose();
          setRequiredCredits(response?.data?.data);
          dispatch({
            type: 'SET_WC_ORDER_STATUS',
            data: false
          });
          setNeededCreditsVisible(true);
        } else {
          handleDeductCredits();
        }
      })
      ?.catch((error) => {
        dispatch({
          type: 'SET_WC_ORDER_STATUS',
          data: false
        });
        handleConfigurationClose();
        setCreditsLoading(false);
        return error;
      });
  };

  const selectAddOns = (value) => {
    setData({ ...data, addOns: value });
  };

  const selectAccesories = (value) => {
    setData({ ...data, accessories: value });
  };

  const onChange = (event) => {
    const type = event?.target?.value;
    setCardType(type);
    setCardSubtype();
  };
  const disablePlaceOrder = () => {
    if (cardType === '') {
      return true;
    }
    if (!cardType && !cardSubtype) {
      return true;
    }
    if (
      cardType === cardTypes?.plasticCard ||
      cardType === cardTypes?.userLicense
    ) {
      return false;
    }
    if (cardType === 'wood' || cardType === 'metal') {
      if (!cardSubtype) {
        return true;
      }
      return false;
    }
    return true;
  };

  const disableOrder = disablePlaceOrder();

  const onChangeNest = (event) => {
    const nestedValue = event?.target?.value;
    setCardSubtype(nestedValue);
    if (nestedValue?.includes('wood')) {
      setCardType('wood');
    } else if (nestedValue?.includes('metal')) {
      setCardType('metal');
    }
  };

  const handlePlaceOrder = () => {
    requiredCreditsData.count = selectedUsers?.length;
    if (cardType !== null && cardType !== undefined) {
      if (cardType === CARD_TYPES?.PLASTIC) {
        requiredCreditsData.cards = MOBILO_PRODUCTS?.mobiloCardCustom;
        requiredCreditsData.licenseType = [
          ...requiredCreditsData?.licenseType,
          MOBILO_PRODUCTS?.team
        ];
      } else if (cardType === CARD_TYPES?.WOOD) {
        requiredCreditsData.cards = MOBILO_PRODUCTS?.mobiloWood;
        requiredCreditsData.licenseType = [
          ...requiredCreditsData?.licenseType,
          MOBILO_PRODUCTS?.team
        ];
      } else if (cardType === CARD_TYPES?.METAL) {
        requiredCreditsData.cards = MOBILO_PRODUCTS?.mobiloMetal;
        requiredCreditsData.licenseType = [
          ...requiredCreditsData?.licenseType,
          MOBILO_PRODUCTS?.team
        ];
      } else if (cardType === CARD_TYPES?.USER_LICENSE) {
        requiredCreditsData.licenseType = [
          ...requiredCreditsData?.licenseType,
          MOBILO_PRODUCTS?.team
        ];
      }
    }

    if (data?.addOns?.length > 0) {
      if (data?.addOns?.includes(ADD_ONS?.CRMIntegrationAndAutomation)) {
        requiredCreditsData.licenseType = [
          ...requiredCreditsData?.licenseType,
          MOBILO_PRODUCTS?.apiAuto
        ];
      }
      // Commenting this for now, might be used later
      // if (data?.addOns?.includes(ADD_ONS?.DataEnrichement)) {
      //   requiredCreditsData.licenseType = [
      //     ...requiredCreditsData?.licenseType,
      //     MOBILO_PRODUCTS?.clearBit
      //   ];
      // }
      if (data?.addOns?.includes(ADD_ONS?.CustomPackage)) {
        requiredCreditsData.licenseType = [
          ...requiredCreditsData?.licenseType,
          MOBILO_PRODUCTS?.customDigi
        ];
      }
    }

    if (cardType !== CARD_TYPES?.USER_LICENSE) {
      if (data?.accessories?.includes(ACCESSORIES?.NFCSmartButton)) {
        requiredCreditsData.type = [MOBILO_PRODUCTS?.smartButton];
      }
      if (data?.accessories?.includes(ACCESSORIES?.NFCKeyFob)) {
        requiredCreditsData.type = [MOBILO_PRODUCTS?.keyFob];
      }
      if (
        data?.accessories?.includes(ACCESSORIES?.NFCSmartButton) &&
        data?.accessories?.includes(ACCESSORIES?.NFCKeyFob)
      ) {
        requiredCreditsData.type = [
          `${MOBILO_PRODUCTS?.keyFob}_${MOBILO_PRODUCTS?.smartButton}`
        ];
      }
      if (
        !data?.accessories?.includes(ACCESSORIES?.NFCSmartButton) &&
        !data?.accessories?.includes(ACCESSORIES?.NFCKeyFob) &&
        cardType !== ''
      ) {
        requiredCreditsData.type = ['card_only'];
      }
    }
    // eslint-disable-next-line no-undef
    localStorage?.setItem(
      REQUIRED_CREDITS_DATA,
      JSON?.stringify(requiredCreditsData)
    );
    fetchNeededCredits();
  };

  return (
    <div>
      <CommonModal
        previewVisible={configurationModalVisible}
        setPreviewVisible={setConfigurationModalVisible}
        previewTitle="New Account Configuration"
        onCancel={handleConfigurationClose}
        closable={!deductCreditsLoading}
        maskClosable={false}
      >
        <Form name="validate_other">
          <div className="new-configuration-main">
            <div className="pl-30">
              <span className="font-11">Assigned Products:</span>
              <div className="assigned-products-list">
                <span className="font-10-bold"> Business Card: </span>
                <div className="pl-15">
                  <Form.Item>
                    <Radio.Group
                      onChange={onChange}
                      value={cardType}
                      disabled={creditsLoading || deductCreditsLoading}
                    >
                      <Radio value={cardTypes?.userLicense}>
                        {cardTypes?.userLicense}
                      </Radio>
                      <Radio value={cardTypes?.plasticCard}>
                        {cardTypes?.plasticCard}
                      </Radio>
                      <Radio value="wood">
                        Wood Card
                        {cardType === 'wood' && (
                          <Radio.Group
                            disabled={creditsLoading || deductCreditsLoading}
                            onChange={onChangeNest}
                            value={cardSubtype}
                            options={cardTypes?.woodCard}
                          />
                        )}
                      </Radio>
                      <Radio value="metal">
                        Metal Card
                        {cardType === 'metal' && (
                          <Radio.Group
                            disabled={creditsLoading || deductCreditsLoading}
                            onChange={onChangeNest}
                            value={cardSubtype}
                            options={cardTypes?.metalCard}
                          />
                        )}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </div>
              <div className="assigned-products-list">
                <span className="font-10-bold ">Add Ons: (Optional) </span>
                <div className="pl-15">
                  <Form.Item>
                    <Checkbox.Group
                      options={addOns}
                      onChange={selectAddOns}
                      disabled={creditsLoading || deductCreditsLoading}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="assigned-products-list">
                <span className="font-10-bold ">Accessories: (Optional)</span>
                <div className="pl-15">
                  <Form.Item>
                    <Checkbox.Group
                      disabled={
                        cardType === cardTypes?.userLicense ||
                        creditsLoading ||
                        deductCreditsLoading
                      }
                      options={accessories}
                      onChange={selectAccesories}
                    />
                  </Form.Item>
                </div>
              </div>
              {currentUser?.allowInstructionAndTagging && (
                <>
                  <div>
                    <Checkbox
                      disabled={creditsLoading || deductCreditsLoading}
                      onChange={() =>
                        setSendLoginInstructions(!sendLoginInstructions)
                      }
                    >
                      Send login instructions to the new user
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      onChange={() => setSendProductInfo(!sendProductInfo)}
                      disabled={creditsLoading || deductCreditsLoading}
                    >
                      Send product tips, updates, and mobilo news to the new
                      users
                    </Checkbox>
                  </div>
                </>
              )}
            </div>
            <div className="form-footer">
              <Form.Item>
                <CommonButton
                  disabled={disableOrder}
                  key="submit"
                  type="primary"
                  onClick={handlePlaceOrder}
                  loading={creditsLoading || deductCreditsLoading}
                >
                  Place Order
                </CommonButton>
              </Form.Item>
            </div>
          </div>
        </Form>
      </CommonModal>
      {neededCreditsVisible && (
        <NeededCredits
          neededCreditsVisible={neededCreditsVisible}
          setNeededCreditsVisible={setNeededCreditsVisible}
          setConfigurationModalVisible={setConfigurationModalVisible}
          requiredCreditsData={requiredCreditsData}
          requiredCredits={requiredCredits}
          fetchNeededCredits={fetchNeededCredits}
          handleConfigurationClose={handleConfigurationClose}
        />
      )}

      <OrderConfirmation
        confirmationVisible={confirmationVisible}
        setConfirmationVisible={setConfirmationVisible}
        fetchNewUsers={fetchNewUsers}
      />
    </div>
  );
};

export default NewAccountConfiguration;
